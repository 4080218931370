import { useCallback, useEffect, useState } from 'react'
import styles from './Stats.module.scss'
import useTranslation from '../Hooks/useTranslation'
import GraphLine from './Graph/GraphLine'
import GraphPie from './Graph/GraphPie'
import { useLiveQuery } from "dexie-react-hooks"
import useSales from '../Hooks/useSales'
import useHelpers from '../Hooks/useHelpers'
import moment from 'moment'
import 'moment/dist/locale/fr';
import usePrefStore from '../stores/prefs';
// import { AppWriteContext } from '../Contexts/AppWriteContext';
import { db as dexie_db } from "../stores/db"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotate } from '@fortawesome/free-solid-svg-icons'
import useModelsStore from '../stores/models'

const modes = {
   ca: 1,
   pieces: 2,
   confies: 1,
   confies_ral: 2,
}

function Stats(props) {
   // States
   // const [grafikDetail, setGrafikDetail] = useState(false)
   const [grafikMode, setGrafikMode] = useState(modes.ca)
   const [confiesMode, setConfiesMode] = useState(modes.confies)
   // const [dataStats, setDataStats] = useState([])
   const [dataStats, setDataStats] = useState([])
   const [dataAnnualStats, setDataAnnualStats] = useState([])
   const [customer, setCustomer] = useState(null)
   const [curYear, setCurYear] = useState(null)
   const [curMonth, setCurMonth] = useState(null)
   const [currencyName, setCurrencyName] = useState(null)
   const [currency, setCurrency] = useState(null)
   const [mainIsEuro, setMainIsEuro] = useState(true)
   const [diviseur, setDiviseur] = useState('')
   const [salesYearEuro, setSalesYearEuro] = useState(null)
   const [salesYearDollar, setSalesYearDollar] = useState(null)
   const [salesPrevYearEuro, setSalesPrevYearEuro] = useState(null)
   const [salesPrevYearDollar, setSalesPrevYearDollar] = useState(null)
   const [monthSalesYear, setMonthSalesYear] = useState(null)
   const [monthSalesPrevYear, setMonthSalesPrevYear] = useState(null)
   const [piecesYear, setPiecesYear] = useState(null)
   const [piecesPrevYear, setPiecesPrevYear] = useState(null)
   const [valRALEuro, setValRALEuro] = useState(null)
   const [valRALDollar, setValRALDollar] = useState(null)
   const [qteRAL, setQteRAL] = useState(null)
   const [valConfiesEuro, setValConfiesEuro] = useState(null)
   const [valConfiesDollar, setValConfiesDollar] = useState(null)
   const [qteConfies, setQteConfies] = useState(null)
   const [valConfiesRAL, setValConfiesRAL] = useState(null)
   const [qteConfiesRAL, setQteConfiesRAL] = useState(null)
   //
   const { trans, lowtrans } = useTranslation()
   const { getCustomerById, getSyncOrdersForCustomerId, getSyncOrdersAsArray } = useSales()
   const { formatPriceKM, formatNumber } = useHelpers()
   const live_sync_orders = useLiveQuery(async () => {
      let ret
      if (props.customer) {
         ret = await getSyncOrdersForCustomerId(props.customer)
      } else {
         ret = await getSyncOrdersAsArray()
      }
      // console.log(ret)
      return ret
   }, [props])
   const { currentLang: curLang } = usePrefStore();
   //
   const { devises } = useModelsStore()

   useEffect(() => {
      const theCustomer = getCustomerById(props.customer)
      const theCurrency = theCustomer?.tarifs.ces.currency

      setCustomer(theCustomer)
      if (theCurrency) {
         setMainIsEuro(theCurrency === 'EUR')
      } else {
         setMainIsEuro(true)
      }

   }, [getCustomerById, props.customer])

   // console.log(customer)
   // console.log(mainIsEuro)

   const calcTots = useCallback(async () => {
      const month_tots_n = {}
      const month_tots_n1 = {}
      const thisYear = moment().year()
      const thisMonth = moment().month() + 1 // 0-11

      setCurYear(thisYear)
      setCurMonth(thisMonth)
      // console.log('***live_sync_orders', customer, props, live_sync_orders);
      if (live_sync_orders) {
         // Calcul des totaux mensuels
         // console.log('**** START');
         for (const an_order of live_sync_orders) {
            const month = moment(an_order.date).month()

            // if (moment(an_order.date).isBetween(`${thisYear}-01-01`, `${thisYear}-12-31`)) {
            if (moment(an_order.date).year() === thisYear) {
               // console.log('**** THIS YEAR', an_order);
               if (month_tots_n[an_order.currency] === undefined) {
                  month_tots_n[an_order.currency] = [null, null, null, null, null, null, null, null, null, null, null, null]
               }
               for (const a_line of an_order.lines) {
                  if (month_tots_n[an_order.currency][month] === null) {
                     month_tots_n[an_order.currency][month] = a_line.price * a_line.invoiced_qty
                  } else {
                     month_tots_n[an_order.currency][month] += a_line.price * a_line.invoiced_qty
                  }
                  // console.log('**** A_LINE', month, a_line.price, a_line.invoiced_qty, a_line.price * a_line.invoiced_qty, month_tots_n[an_order.currency][month]);
               }
            } else if (moment(an_order.date).year() === (thisYear - 1)) {
               if (month_tots_n1[an_order.currency] === undefined) {
                  month_tots_n1[an_order.currency] = [null, null, null, null, null, null, null, null, null, null, null, null]
               }
               for (const a_line of an_order.lines) {
                  if (month_tots_n1[an_order.currency][month] === null) {
                     month_tots_n1[an_order.currency][month] = a_line.price * a_line.invoiced_qty
                  } else {
                     month_tots_n1[an_order.currency][month] += a_line.price * a_line.invoiced_qty
                  }
               }
            }
         }
      }

      let res
      if (props.customer) {
         res = await dexie_db.ventes_ral.get(props.customer);
         // devise: appWriteObject.devise_vente,
         // ca_devise_n: appWriteObject.ca_devise_n,
         // ca_euro_n: appWriteObject.ca_euro_n,
         // ca_dollar_n: appWriteObject.ca_dollar_n,

         setSalesYearEuro(res?.ca_euro_n)
         setSalesYearDollar(res?.ca_dollar_n)
         setPiecesYear(res?.qte_n)
         setSalesPrevYearEuro(res?.ca_euro_n1)
         setSalesPrevYearDollar(res?.ca_dollar_n1)
         setPiecesPrevYear(res?.qte_n1)
         setValRALEuro(res?.ral_euro)
         setValRALDollar(res?.ral_dollar)
         setQteRAL(res?.ral)
         // console.log('****ICI');
      } else {
         res = await dexie_db.ventes_ral.toArray();

         let sales_euro_n = 0
         let sales_dollar_n = 0
         let sales_euro_n1 = 0
         let sales_dollar_n1 = 0
         let qte_n = 0
         let qte_n1 = 0
         let ral_euro = 0
         let ral_dollar = 0
         let ral = 0
         for (const a_ral of res) {
            sales_euro_n += a_ral.ca_euro_n
            sales_dollar_n += a_ral.ca_dollar_n
            sales_euro_n1 += a_ral.ca_euro_n1
            sales_dollar_n1 += a_ral.ca_dollar_n1
            qte_n += a_ral.qte_n
            qte_n1 += a_ral.qte_n1
            ral_euro += a_ral.ral_euro
            ral_dollar += a_ral.ral_dollar
            ral += a_ral.ral
         }
         setSalesYearEuro(sales_euro_n)
         setSalesYearDollar(sales_dollar_n)
         setPiecesYear(qte_n)
         setSalesPrevYearEuro(sales_euro_n1)
         setSalesPrevYearDollar(sales_dollar_n1)
         setPiecesPrevYear(qte_n1)
         setValRALEuro(ral_euro)
         setValRALDollar(ral_dollar)
         setQteRAL(ral)
      }

      if (props.customer) {
         res = await dexie_db.confies.get(props.customer);
         setValConfiesEuro(res?.ca_site_euro)
         setValConfiesDollar(res?.ca_site_dollar)
         setQteConfies(res?.qte_site)

         res = await dexie_db.confies_ral.get(props.customer);
         setValConfiesRAL(res?.ca)
         setQteConfiesRAL(res?.qte)
      } else {
         res = await dexie_db.confies.toArray();

         let val_euro = 0
         let val_dollar = 0
         let qte = 0
         for (const a_ral of res) {
            val_euro += a_ral.ca_site_euro
            val_dollar += a_ral.ca_site_dollar
            qte += a_ral.qte_site
         }
         setValConfiesEuro(val_euro)
         setValConfiesDollar(val_dollar)
         setQteConfies(qte)
      }

      let found_currency = null

      console.log(month_tots_n, month_tots_n1)
      if (month_tots_n) {
         const currencies = Object.keys(month_tots_n)
         let month_tot = month_tots_n[currencies[0]]

         found_currency = currencies[0]
         setMonthSalesYear(month_tot)
      } else {
         setMonthSalesYear(null)
      }
      if (month_tots_n1) {
         const currencies = Object.keys(month_tots_n1)
         let month_tot = month_tots_n1[currencies[0]]

         if (!found_currency) {
            found_currency = currencies[0]
         }
         setMonthSalesPrevYear(month_tot)
      } else {
         setMonthSalesPrevYear(null)
      }

      if (devises) {
         setCurrency(devises[found_currency]?.label[curLang] ?? found_currency)
      } else {
         setCurrency(found_currency)
      }
      setCurrencyName(found_currency)

   }, [curLang, devises, live_sync_orders, props.customer])

   useEffect(() => {
      calcTots()
   }, [calcTots])

   useEffect(() => {
      moment.locale(curLang)
      const names = moment.monthsShort().map(month => month.toUpperCase())
      const data = []
      const data_prev = []
      let cur_val

      // console.log(monthSalesYear)
      // console.log(monthSalesPrevYear)
      cur_val = 0
      // console.log(cur_month);
      for (let loop = 0; loop < curMonth; loop++) {
         cur_val += monthSalesYear ? monthSalesYear[loop] : 0
         data.push({
            x: names[loop],
            y: cur_val
         })
      }
      cur_val = 0
      for (let loop = 0; loop < 12; loop++) {
         cur_val += monthSalesPrevYear ? monthSalesPrevYear[loop] : 0
         data_prev.push({
            x: names[loop],
            y: cur_val
         })
      }

      // console.log(data);
      // console.log(data_prev);

      setDataAnnualStats(
         [
            {
               "id": curYear - 1,
               "color": "#C8449B",
               "data": data_prev
            },
            {
               "id": curYear,
               "color": "#18C044",
               "data": data
            },
         ])
   }, [curLang, curMonth, curYear, monthSalesPrevYear, monthSalesYear])

   useEffect(() => {
      if (grafikMode === modes.ca) {
         let val = mainIsEuro ? (salesYearEuro ?? 0) : (salesYearDollar ?? 0)
         let val_prev = mainIsEuro ? (salesPrevYearEuro ?? 0) : (salesPrevYearDollar ?? 0)
         let curDiviseur = 1

         if ((val >= 1000000) || (val_prev > 1000000)) {
            curDiviseur = 1000000
            setDiviseur('M')
         } else if ((val >= 1000) || (val_prev > 1000)) {
            curDiviseur = 1000
            setDiviseur('K')
         }
         val = Math.floor(val * 10 / curDiviseur) / 10
         val_prev = Math.floor(val_prev * 10 / curDiviseur, 1) / 10
         setDataStats([
            {
               "id": `hjo_${curYear - 1}`,
               "label": curYear - 1,
               "value": val_prev,
               "color": "rgb(214, 176, 224)"
            },
            {
               "id": `hjo_${curYear}`,
               "label": curYear,
               "value": val,
               "color": "#19B582"
            },
         ])
      } else {
         setDataStats([
            {
               "id": `hjo_${curYear - 1}`,
               "label": curYear - 1,
               "value": piecesPrevYear ?? 0,
               "color": "rgb(214, 176, 224)"
            },
            {
               "id": `hjo_${curYear}`,
               "label": curYear,
               "value": piecesYear ?? 0,
               "color": "#19B582"
            },
         ])
      }
   }, [curYear, grafikMode, mainIsEuro, piecesPrevYear, piecesYear, salesPrevYearDollar, salesPrevYearEuro, salesYearDollar, salesYearEuro])

   console.log('*** salesYearEuro', salesYearEuro)
   console.log('*** salesPrevYearEuro', salesPrevYearEuro)
   console.log('*** dataStats', dataStats)
   console.log('*** dataAnnualStats', dataAnnualStats)

   return (
      <div className={`${styles.stats} ${!props.customer ? styles.homepage : ''}`}>
         <div className={styles.stat_cards}>
            {/* <div className={`${styles.info_cards} ${grafikDetail ? styles.detail : ''}`}> */}
            <div className={styles.info_cards}>
               {props.customer &&
                  <div className={styles.card}>
                     <div>{trans("?Limite crédit")}</div>
                     <div>{customer?.credit && formatPriceKM(customer?.credit, customer?.tarifs?.ces?.currency ?? "???")}</div>
                  </div>
               }
               <div className={styles.card}>
                  <div>{trans("?Ventes")} {curYear}</div>
                  {mainIsEuro &&
                     <>
                        <div>{formatPriceKM(salesYearEuro ?? 0, "EUR", 100000, 1)}</div>
                        <div>{formatPriceKM(salesYearDollar ?? 0, "USD", 100000, 1)}</div>
                     </>
                  }
                  {!mainIsEuro &&
                     <>
                        <div>{formatPriceKM(salesYearDollar ?? 0, "USD", 100000, 1)}</div>
                        <div>{formatPriceKM(salesYearEuro ?? 0, "EUR", 100000, 1)}</div>
                     </>
                  }
                  <div>{(piecesYear !== null) && formatNumber(piecesYear ?? 0)} {lowtrans("?Pièce", Math.abs(piecesYear ?? 0) > 1)}</div>
               </div>
               <div className={styles.card}>
                  <div>{trans("?Reste à livrer")}</div>
                  {mainIsEuro &&
                     <>
                        <div>{formatPriceKM(valRALEuro ?? 0, "EUR", 100000, 1)}</div>
                        <div>{formatPriceKM(valRALDollar ?? 0, "USD", 100000, 1)}</div>
                     </>
                  }
                  {!mainIsEuro &&
                     <>
                        <div>{formatPriceKM(valRALDollar ?? 0, "USD", 100000, 1)}</div>
                        <div>{formatPriceKM(valRALEuro ?? 0, "EUR", 100000, 1)}</div>
                     </>
                  }
                  <div>{(qteRAL !== null) && formatNumber(qteRAL ?? 0)} {lowtrans("?Pièce", Math.abs(qteRAL ?? 0) > 1)}</div>
               </div>
               {props.customer && (confiesMode === modes.confies) &&
                  <div className={styles.card}>
                     {(qteConfiesRAL > 0) && <FontAwesomeIcon class={styles.confies_mode_swap} icon={faRotate} onClick={() => setConfiesMode(modes.confies_ral)} />}
                     <div>{trans("?Confié", true)}</div>
                     {mainIsEuro &&
                        <>
                           <div>{formatPriceKM(valConfiesEuro ?? 0, "EUR", 100000, 1)}</div>
                           <div>{formatPriceKM(valConfiesDollar ?? 0, "USD", 100000, 1)}</div>
                        </>
                     }
                     {!mainIsEuro &&
                        <>
                           <div>{formatPriceKM(valConfiesDollar ?? 0, "USD", 100000, 1)}</div>
                           <div>{formatPriceKM(valConfiesEuro ?? 0, "EUR", 100000, 1)}</div>
                        </>
                     }
                     <div>{(qteConfies !== null) && formatNumber(qteConfies ?? 0)} {lowtrans("?Pièce", Math.abs(qteConfies ?? 0) > 1)}</div>
                  </div>
               }
               {props.customer && (confiesMode === modes.confies_ral) &&
                  <div className={styles.card}>
                     <FontAwesomeIcon class={styles.confies_mode_swap} icon={faRotate} onClick={() => { setConfiesMode(modes.confies) }} />
                     <div>{trans("?Confié", true)} - {trans("?Reste à livrer", true)}</div>
                     <div>{formatPriceKM(valConfiesRAL ?? 0, currencyName, 100000, 1)}</div>
                     <div>{formatNumber(qteConfiesRAL ?? 0)} {lowtrans("?Pièce", Math.abs(qteConfiesRAL ?? 0) > 1)}</div>
                  </div>
               }
            </div>
            <div className={styles.grafik} onClick={() => { setGrafikMode(cur => ((cur === modes.ca) ? modes.pieces : modes.ca)) }}>
               {(salesYearEuro || salesPrevYearEuro) &&
                  <>
                     <FontAwesomeIcon icon={faRotate} />
                     <GraphPie data={dataStats} legendTranslateX={props.customer ? -90 : -60} center_title={grafikMode === modes.ca ? `${diviseur}${mainIsEuro ? '€' : '$'}` : trans("?Qté")} />
                  </>
               }
            </div>
         </div>
         <div className={styles.annual_stats}>
            <div>
               <GraphLine data={dataAnnualStats} legendX={trans("?Mois")} legendY={`${trans("?chiffre_affaires")} (${currency ?? '?'})`} />
            </div>
         </div>
      </div>
   )
}

export default Stats
