import styles_Searchbar from '../components/Searchbar.module.scss';
import styles_DropdownMenu from '../components/DropdownMenu.module.scss';
import styles from './OrderDetail.module.scss';
import "../scss/datepicker.scss";
import { Fragment, forwardRef, useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faGem, faFileLines as faFileLinesSolid } from '@fortawesome/free-solid-svg-icons';
import { faHandHoldingBox, faFilePen, faShelves, faGem as faGemProSolid, faPenField, faPen, faBox, faLinkHorizontalSlash, faSignature as faSignatureBold } from '@fortawesome/pro-solid-svg-icons'
import { faBadgeCheck, faFilePdf, faClone, faCloudArrowUp, faSignature, faTrashCan, faPenToSquare, faFileLines, faHandHoldingBox as faHandHoldingBoxProLight, faFilePen as faFilePenProLight, faShelves as faShelvesProLight, faGem as faGemProLight } from '@fortawesome/pro-light-svg-icons';
import { faTag, faMemo } from '@fortawesome/pro-regular-svg-icons';
import { faCloud, faCloudXmark, faTrashXmark } from '@fortawesome/pro-solid-svg-icons'
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { fr, enUS } from 'date-fns/locale';
import DropdownMenu from '../components/DropdownMenu';
import Menu from '../components/Menu/Menu';
import Searchbar from '../components/Searchbar';
import CommentEditor from '../components/CommentEditor';
import MailAddressPicker from '../components/MailAddressPicker';
import AddressPicker from '../components/AddressPicker';
import Button from '../components/Button';
import Link from '../components/Link';
import QtyPicker from '../components/QtyPicker'
// import DatePicker from '../components/DatePicker'
import Confirm from '../components/Confirm'
import Signature from '../components/Signature'
import usePrefStore from '../stores/prefs';
import useHelpers from '../Hooks/useHelpers';
import useTranslation from '../Hooks/useTranslation';
import useSales from '../Hooks/useSales';
import useTarifs from '../Hooks/useTarifs';
import useCollections from '../Hooks/useCollections';
import { useSearchParams } from 'react-router-dom';
// import { useLogger } from '../Contexts/Logger';
// import { useState } from "react"
import { useLiveQuery } from "dexie-react-hooks"
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { CatalogFilterContext } from '../Contexts/CatalogFilterContext';
import { GlobalContext } from '../Contexts/GlobalContext';
import { ToastContainer, toast } from 'react-toastify'
import { AppWriteContext } from '../Contexts/AppWriteContext';

const menu_items = {
   setActive: "active",
   sendPdf: "sendpdf",
   doClone: "duplicate",
   doSync: "sync",
   doDelete: "delete",
   doSign: "sign",
}

const line_menu_items = {
   doEdit: "edit",
   doDelete: "delete",
   doViewInCatalog: "catalog",
}

const confirm_type = {
   conf_delete_line: "del_line",
   conf_delete_order: "del_order",
}

const search_type = {
   in_catalog: "in_catalog",
   in_order: "in_order",
   manu_sku: "manu_sku",
}

registerLocale('fr', fr)
registerLocale('en-US', enUS)
setDefaultLocale('fr')

function OrderDetail() {
   // // const allOrders = usePrefStore((state) => state.orders);
   // // const setAllOrders = usePrefStore((state) => state.setOrders);
   // const curOrder = usePrefStore((state) => state.curOrder);
   // const curLang = usePrefStore((state) => state.currentLang)
   // const showWeeksNum = usePrefStore((state) => state.showWeeksNum)
   // const searchModeInOrder = usePrefStore((state) => state.searchModeInOrder)
   // const setSearchModeInOrder = usePrefStore((state) => state.setSearchModeInOrder)
   // const setCurOrder = usePrefStore((state) => state.setCurOrder);
   const { curOrder, currentLang: curLang, showWeeksNum, searchModeInOrder, setSearchModeInOrder, setCurOrder } = usePrefStore();
   //
   const { isDarkLayerVisible, setIsDarkLayerVisible, setKeepSearchBarAccessible } = { ...GlobalContext() }
   //
   const [searchBar, setSearchBar] = useState(null);
   const [searchText, setSearchText] = useState(null);
   const [filteredLines, setFilteredLines] = useState(null);
   const [lines, setLines] = useState(null);
   // const [order, setOrder] = useState(null)
   const [customer, setCustomer] = useState(null)
   const [region, setRegion] = useState(null)         // On n'est pas forcément sur le client ou la commande sélectionné(e), on va avoir besoin de spécifier la devise d'affichage
   const [currency, setCurrency] = useState(null)     // On n'est pas forcément sur le client ou la commande sélectionné(e), on va avoir besoin de spécifier la devise d'affichage
   const [showDropdownMenuGlobal, setShowDropdownMenuGlobal] = useState(false);
   const [dropdownMenuGlobal, setDropdownMenuGlobal] = useState(null);
   const [dropdownMenuLine, setDropdownMenuLine] = useState(null);
   const [showDropdownMenuOnLineNum, setShowDropdownMenuOnLineNum] = useState(null);
   const [lastSelectedLineNum, setLastSelectedLineNum] = useState(null);   // Sert lors de la màj d'une ligne pour savoir quelle ligne a été choisie
   const [isQtyPickerVisible, setIsQtyPickerVisible] = useState(false) //16
   const [isAddressPickerVisible, setIsAddressPickerVisible] = useState(false)
   const [isConfirmVisible, setIsConfirmVisible] = useState(false)
   const [isSignatureVisible, setIsSignatureVisible] = useState(false)
   // const [isEditingComment, setIsEditingComment] = useState(false)
   const [confirmType, setConfirmType] = useState(null)
   const [editedLineNum, setEditedLineNum] = useState(null) // N° de la ligne en cours d'édition
   const [editedModel, setEditedModel] = useState(null)     // ID Modèle de la ligne en cours d'édition
   const [editedColor, setEditedColor] = useState(null)     // Couleur de la ligne en cours d'édition
   const [editedSize, setEditedSize] = useState(null)       // Taille de la ligne en cours d'édition
   const [editedQty, setEditedQty] = useState(null)         // Quantité de la ligne en cours d'édition
   const [editedLineComment, setEditedLineComment] = useState(null) // Commentaire ligne en cours d'édition
   const [searchId, setSearchId] = useState(null)           // ID passé en param sur URL
   const [editedAllVariations, setEditedAllVariations] = useState(null)           // Toutes les variations de couleur+taille présentes dans la commande pour le modèle  de la ligne en cours d'édition. Utile pour le QtyPicker en mode multi
   const [totalForOrder, setTotalForOrder] = useState()
   const [itemsCountForOrder, setItemsCountForOrder] = useState()
   const [dropdownMenuOrderType, setDropdownMenuOrderType] = useState()
   const [isMenuOrderTypeVisible, setIsMenuOrderTypeVisible] = useState(false)
   const [isContremarqueEditorVisible, setIsContremarqueEditorVisible] = useState(false)
   const [isCommentEditorVisible, setIsCommentEditorVisible] = useState(false)
   const [isMailAddressPickerVisible, setIsMailAddressPickerVisible] = useState(false)
   const [isLineCommentEditorVisible, setIsLineCommentEditorVisible] = useState(false)
   const [isReadOnly, setIsReadOnly] = useState(false)
   const [isSelected, setIsSelected] = useState(false)
   //
   const { order_status, createOrUpdateLinesInOrder, setOrderIdAsCurrent, getOrderById, getCustomerById, deleteSingleLineInOrder, deleteOrder, updateOrder, type_order, calcTotalForOrder, getItemsCountForOrder, cloneOrder, canSyncOrder } = useSales()
   const { getModelById, makeSKU, splitSKU } = useCollections()
   const { getCessPriceForModelColorSize, getCessPriceForModelBySKU, findRegionFromCessionTarifNames, findRegionFromPublicTarifNames } = useTarifs()
   //
   const { formatCESPrice, formatNumber } = useHelpers();
   const { trans, transWithParams } = useTranslation();
   const { clearAllFilters, setSearchText: setCatalogSearchText } = CatalogFilterContext()
   const { awPushPDF } = { ...AppWriteContext() }
   const [searchParams] = useSearchParams()
   const live_order = useLiveQuery(async () => await getOrderById(searchId), [searchId])
   const navigate = useNavigate()

   const CustomDate = forwardRef((props, ref) => {
      return <span onClick={props.onClick} ref={ref}>
         {props.text}
      </span>
   });

   useEffect(() => {
      setIsSelected(curOrder && (curOrder === live_order?.id))
      if (!searchModeInOrder || ((searchModeInOrder === search_type.manu_sku) && (isReadOnly || !live_order || (curOrder !== live_order?.id)))) {
         setSearchModeInOrder(search_type.in_catalog)
      }
   }, [curOrder, isReadOnly, live_order, searchModeInOrder, setSearchModeInOrder])

   useEffect(() => {
      setSearchId(searchParams.get('id'))
   }, [searchParams])

   useEffect(() => {
      // console.log('LIVE ORDER', live_order);
      setTotalForOrder(calcTotalForOrder(live_order))
      setItemsCountForOrder(getItemsCountForOrder(live_order))
   }, [calcTotalForOrder, getItemsCountForOrder, live_order])

   useEffect(() => {
      if (live_order) {
         // console.log('++++', live_order);
         // setOrder(live_order)
         setCustomer(getCustomerById(live_order.customer_id))
         setIsReadOnly(live_order.readonly)
      }
   }, [getCustomerById, live_order])

   useEffect(() => {
      setCurrency(customer?.tarifs.ces.currency)  // Dans le menu on n'est pas forcément sur le client ou la commande sélectionné(e), on doit donc cibler explicitement la bonne devise
      // const a_region = findRegionFromTarifNames(customer?.tarifs.ppu.tarif, customer?.tarifs.ppu.currency)
      let a_region = findRegionFromCessionTarifNames(customer?.tarifs.ces.tarif, customer?.tarifs.ces.currency)
      if (!a_region) {
         // !!!!! NB : OUI, c'est bien CES qui est pris dans a_customer pour chercher la région. C'est pour les clients qui n'ont pas de tarif cession mais un tarif public remisé
         a_region = findRegionFromPublicTarifNames(customer?.tarifs.ces.tarif, customer?.tarifs.ces.currency)
      }
      setRegion(a_region)

   }, [customer, findRegionFromCessionTarifNames, findRegionFromPublicTarifNames])

   const onSelectGlobal = useCallback((id) => {
      setShowDropdownMenuGlobal(false)
      if (id === menu_items.setActive) {
         setOrderIdAsCurrent(live_order?.id)
      } else if (id === menu_items.doClone) {
         cloneOrder(live_order?.id).then(new_id => {
            setOrderIdAsCurrent(new_id)
            navigate(`/order-detail?id=${new_id}`, { replace: false })
            toast.success(transWithParams('?order_cloned', [new_id]), {
               position: "top-right",
               autoClose: 2500,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               // progress: ,
               theme: "colored",
            });
         })
      } else if (id === menu_items.sendPdf) {
         setIsMailAddressPickerVisible(true)
      } else if (id === menu_items.doSync) {
         updateOrder(live_order?.id, {
            status: order_status.locked4sync,   // On va essayer de la pousser sur AppWrite (c'est updateOrder qui s'en charge, elle va ensuite passer en locked)
            readonly: true,
         })
      } else if (id === menu_items.doDelete) {
         setConfirmType(confirm_type.conf_delete_order)
         setIsConfirmVisible(true)
      } else if (id === menu_items.doSign) {
         setIsSignatureVisible(true)
      }
   }, [cloneOrder, live_order?.id, navigate, order_status.locked4sync, setOrderIdAsCurrent, transWithParams, updateOrder])

   const onSelectLine = useCallback((id) => {
      console.log('ID>>', id, showDropdownMenuOnLineNum);
      const selectedSKU = filteredLines ? filteredLines[showDropdownMenuOnLineNum - 1].sku : null
      const selectedQty = filteredLines ? filteredLines[showDropdownMenuOnLineNum - 1].qty : null
      const { model, color, size } = splitSKU(selectedSKU)

      setEditedLineNum(showDropdownMenuOnLineNum)
      setEditedModel(model)
      setEditedColor(color)
      setEditedSize(size)
      setEditedQty(selectedQty)

      if (id === line_menu_items.doEdit) {
         setIsQtyPickerVisible(true)
         // setEditedAllVariations(
         const arr =
            filteredLines?.reduce((prev, cur_line) => {
               const { model: model2, color: color2, size: size2 } = splitSKU(cur_line.sku)
               console.log('SKU', cur_line.sku);
               console.log('MODEL ?', model2, model);
               console.log('COLOR ?', color2, color);
               console.log('SIZE ?', size2, size);
               if (model2 === model) {
                  return [...prev, { color: color2, size: size2, qty: cur_line.qty }]
               } else {
                  return [...prev]
               }
            }, [])
         // )
         console.log('>>', arr, live_order, filteredLines)
         setEditedAllVariations(arr)
      } else if (id === line_menu_items.doDelete) {
         setConfirmType(confirm_type.conf_delete_line)
         setIsConfirmVisible(true)
      } else if (id === line_menu_items.doViewInCatalog) {
         const selectedSKU = filteredLines ? filteredLines[showDropdownMenuOnLineNum - 1].sku : null
         if (selectedSKU) {
            const { model } = splitSKU(selectedSKU)
            setCatalogSearchText(model)
            // navigate(`/catalog-detail?search=${encodeURIComponent(model)}`, { replace: true })
            navigate('/catalog-detail', { replace: false })
         }
      }
      setShowDropdownMenuOnLineNum(null)
   }, [filteredLines, live_order, navigate, setCatalogSearchText, showDropdownMenuOnLineNum, splitSKU])
   // }, [])

   const onSearch = useCallback(() => {
      if (searchModeInOrder === search_type.in_catalog) {
         const { model, color } = splitSKU(searchText)
         clearAllFilters()
         if (color) {
            setCatalogSearchText(model)
         } else {
            setCatalogSearchText(searchText)
         }
         navigate('/catalog-detail', { replace: false })
      } else if (searchModeInOrder === search_type.manu_sku) {
         const parts = searchText?.toUpperCase().split('X')

         if (parts.length > 0) {
            const { model, color, size } = splitSKU(parts[0])
            const a_model = getModelById(model)

            // console.log('A_MODEL', a_model)
            if (!a_model) {
               // On a la même chose (avec Toaster dans Customers.jsx)
               toast.error(trans('?Impossible d\'ajouter ce SKU à la commande'), {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  // progress: ,
                  theme: "colored",
               });
            } else {
               let qty = Number(parts[1] ?? 1)

               if (!color && !size) {
                  setEditedLineNum(null)
                  setEditedModel(model)
                  setEditedColor(null)
                  setEditedSize(null)
                  setEditedQty(1)   // Permet de ne pas avoir un bouton "Aucune commande sélectionnée"
                  setIsQtyPickerVisible(true)
                  // <QtyPicker model={editedModel} color={editedColor} size={editedSize} multi={editedAllVariations} qty={editedQty} onHide={hideQtyPicker} onUpdate={updateLines} />
               } else {
                  const sku = makeSKU(model, color, size)   // Par sécurité on reconstruit un SKU propre
                  const price = getCessPriceForModelBySKU(sku, region)

                  console.log('::getCessPriceForModelBySKU', sku, region, price);
                  if (qty <= 0) {
                     qty = 1
                  }
                  console.log(live_order.lines, price, searchText);
                  if (price && price.price) {
                     // const lines = [
                     //    ...live_order.lines,
                     //    { price: price.price, qty, sku }
                     // ]

                     // console.log('New lines', lines)
                     // updateOrder(live_order.id, {
                     //    "lines": [...lines]
                     // })
                     createOrUpdateLinesInOrder(live_order.id, [{
                        sku,
                        qty,
                        price: price.price
                     }])

                     // On a la même chose (avec Toaster dans Customers.jsx)
                     toast.success(transWithParams('?item_X_added2order', [sku, qty], qty), {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        // progress: ,
                        theme: "colored",
                     });
                  } else {
                     // On a la même chose (avec Toaster dans Customers.jsx)
                     toast.error(trans('?Impossible d\'ajouter ce SKU à la commande'), {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        // progress: ,
                        theme: "colored",
                     });
                  }
               }
            }
         }
      }
   }, [clearAllFilters, createOrUpdateLinesInOrder, getCessPriceForModelBySKU, getModelById, live_order, makeSKU, navigate, region, searchModeInOrder, searchText, setCatalogSearchText, splitSKU, trans, transWithParams])

   const onOrderChange = useCallback((new_order) => {
      navigate(`/order-detail?id=${new_order}`, { replace: false })
   }, [navigate])

   useEffect(() => {
      setDropdownMenuGlobal(
         <DropdownMenu noscrollbars right={-20} top={30} onSelect={onSelectGlobal}>
            {!isReadOnly && <li disabled={curOrder === live_order?.id} id={menu_items.setActive} >
               <FontAwesomeIcon icon={faBadgeCheck} />
               {trans('?Sélectionner')}
            </li>}
            <li id={menu_items.sendPdf}>
               <FontAwesomeIcon icon={faFilePdf} />
               {trans('?Envoyer en PDF à...')}
            </li>
            <li id={menu_items.doClone}>
               <FontAwesomeIcon icon={faClone} />
               {trans('?Dupliquer')}
            </li>
            {!isReadOnly &&
               <>
                  <li id={menu_items.doSync} disabled={(live_order?.status !== order_status.new) || (live_order && !canSyncOrder(live_order))}>
                     <FontAwesomeIcon icon={faCloudArrowUp} />
                     {trans('?Synchroniser')}
                  </li>
                  <li id={menu_items.doDelete}>
                     <FontAwesomeIcon icon={faTrashCan} />
                     {trans('?Supprimer')}
                  </li>
                  <li id={menu_items.doSign}>
                     <FontAwesomeIcon icon={faSignature} />
                     {trans('?Signature client')}
                  </li>
               </>
            }
         </DropdownMenu>
      );
      setDropdownMenuLine(
         <DropdownMenu noscrollbars right={-20} top={20} onSelect={onSelectLine}>
            <li id={line_menu_items.doEdit}>
               <FontAwesomeIcon icon={faPenToSquare} />
               {trans('?Modifier')}
            </li>
            <li id={line_menu_items.doDelete}>
               <FontAwesomeIcon icon={faTrashCan} />
               {trans('?Supprimer')}
            </li>
            <li id={line_menu_items.doViewInCatalog}>
               <FontAwesomeIcon icon={faGemProLight} />
               {trans('?Voir dans le catalogue')}
            </li>
         </DropdownMenu>
      );
      setSearchBar(
         <Searchbar text={searchText} onSearch={onSearch} lefticon={(searchModeInOrder === search_type.manu_sku) && faPen} placeholder={trans((searchModeInOrder === search_type.manu_sku) ? '?Ajout de ligne par SKU' : ((searchModeInOrder === search_type.in_catalog) ? '?Recherche dans le catalogue' : '?Recherche dans cette commande'))} onChange={(text) => setSearchText(text)} onOrderChange={onOrderChange} >
            <div className={styles_Searchbar.search_tools}>
               <button className={(searchModeInOrder === search_type.in_catalog) ? styles_Searchbar.red : ''} onClick={() => { setSearchModeInOrder(search_type.in_catalog) }}>
                  <FontAwesomeIcon icon={faGem} />
               </button>
               <button className={(searchModeInOrder === search_type.in_order) ? styles_Searchbar.red : ''} onClick={() => { setSearchModeInOrder(search_type.in_order) }}>
                  <FontAwesomeIcon icon={faFileLinesSolid} />
               </button>
               {!isReadOnly && live_order && (curOrder === live_order?.id) &&
                  <button className={(searchModeInOrder === search_type.manu_sku) ? styles_Searchbar.red : ''} onClick={() => { setSearchModeInOrder(search_type.manu_sku) }}>
                     <FontAwesomeIcon icon={faPenField} />
                  </button>
               }
            </div>
         </Searchbar>
      );
   }, [canSyncOrder, curOrder, isReadOnly, live_order, onOrderChange, onSearch, onSelectGlobal, onSelectLine, order_status.new, searchModeInOrder, searchText, setSearchModeInOrder, trans]);

   const onShowMenu = useCallback(
      (evt, id) => {
         evt.stopPropagation();
         if (showDropdownMenuOnLineNum === id) {
            setShowDropdownMenuOnLineNum(null);
         } else {
            setShowDropdownMenuOnLineNum(id);
            setLastSelectedLineNum(id);
         }
         setShowDropdownMenuGlobal(false);
      }, [showDropdownMenuOnLineNum]);

   function makeActive() {
      setOrderIdAsCurrent(live_order?.id)
   }

   // useEffect(()=>{
   //    if (searchModeInOrder === )
   // },[searchModeInOrder])

   useEffect(() => {
      console.log('>>>', searchText);
      if ((searchModeInOrder === search_type.in_order) && (searchText?.trim().length > 0)) {
         const text = searchText?.trim().toUpperCase()
         const arr = live_order?.lines.filter((a_line) => {
            const [model_id, ,] = a_line.sku.split('-')
            const model = getModelById(model_id)

            return a_line.sku.includes(text) || model.label.toUpperCase().includes(text)
         })
         setFilteredLines(arr)
      } else {
         setFilteredLines(live_order?.lines)
      }
   }, [getModelById, live_order, searchModeInOrder, searchText])

   const closeAllDropDowns = useCallback(() => {
      let ret = false

      if (isMenuOrderTypeVisible) {
         setIsMenuOrderTypeVisible(false)
         ret = true
      }
      if (showDropdownMenuGlobal) {
         setShowDropdownMenuGlobal(false)
         ret = true
      }
      if (showDropdownMenuOnLineNum) {
         setShowDropdownMenuOnLineNum(null)
         ret = true
      }
      return ret
   }, [isMenuOrderTypeVisible, showDropdownMenuGlobal, showDropdownMenuOnLineNum])

   const onEditLineComment = useCallback((num_line) => {
      if (!closeAllDropDowns()) {
         setEditedLineNum(num_line)
         setEditedLineComment(filteredLines[num_line - 1].comment)
         setIsLineCommentEditorVisible(true)
      }
   }, [closeAllDropDowns, filteredLines])

   function onCancelEditLineComment() {
      setIsLineCommentEditorVisible(false)
      setEditedLineComment(null)
      setEditedLineNum(null)
   }

   function onValidateEditLineComment(text) {
      const lines = live_order.lines.reduce((prev, cur, index) => {
         if (index === (editedLineNum - 1)) {
            return [...prev, { ...cur, comment: text }]
         } else {
            return [...prev, { ...cur }]
         }
      }, [])

      setIsLineCommentEditorVisible(false)
      updateOrder(live_order.id, {
         "lines": [...lines]
      })
      setEditedLineComment(null)
      setEditedLineNum(null)
   }

   useEffect(() => {
      let num = 0

      // console.log('+++', 'ICI');
      const arr = filteredLines?.map((a_line) => {
         num++
         const [model_id, color, size] = a_line.sku.split('-')
         const model = getModelById(model_id)
         // console.log(model_id, model);
         const cur_num = num  // Pour avoir une variable unique pour chaque ligne plus bas (event menu)
         // const cur_line_comment = a_line.comment
         return (
            <tr key={`line_${num}`}>
               <td className={styles.num_line}>{num}</td>
               <td className={styles.modele_id}>{model_id}</td>
               <td className={styles.model_color}>
                  <div><span>{color}</span></div>
               </td>
               <td className={styles.model_size}>
                  <div><span>{size}</span></div>
               </td>
               <td className={styles.model_label}>{model?.label ?? <FontAwesomeIcon icon={faLinkHorizontalSlash} />}</td>
               <td className={styles.comment} onClick={() => { if (!isReadOnly) onEditLineComment(cur_num) }}>
                  <div>
                     {a_line.comment}
                  </div>
               </td>
               <td className={styles.qty}>{a_line.qty}</td>
               <td className={styles.remain_qty}>{typeof a_line.remain_qty === 'number' ? a_line.remain_qty : a_line.qty}</td>
               <td className={styles.price}>{formatCESPrice(a_line.price, currency)}</td>
               {!isReadOnly && isSelected && <td className={styles.actions}>
                  <span onClick={(evt) => onShowMenu(evt, cur_num)}>
                     {/* <FontAwesomeIcon icon={faGear} /> */}
                     {trans('?Détail', true)}
                     <FontAwesomeIcon icon={faAngleRight} />
                     {showDropdownMenuOnLineNum === num ? dropdownMenuLine : ''}
                  </span>
               </td>
               }
            </tr>
         )
      })
      setLines(arr);
   }, [currency, dropdownMenuLine, filteredLines, formatCESPrice, getModelById, isReadOnly, onEditLineComment, onShowMenu, showDropdownMenuOnLineNum, trans, isSelected]);

   const onSelectOrderType = useCallback((id) => {
      // console.log('ID>>', id, showDropdownMenuOnCustomerId);
      setIsMenuOrderTypeVisible(false)
      if (live_order?.type === type_order.confie) {
         updateOrder(live_order.id, {
            "type": id,
            "addresses": { ...live_order.addresses, ecrins: null }
         })
      } else {
         updateOrder(live_order.id, {
            "type": id,
         })
      }
   }, [live_order, updateOrder, type_order])

   useEffect(() => {
      setDropdownMenuOrderType(
         <DropdownMenu noscrollbars left={-5} top={-5} onSelect={onSelectOrderType}>
            <li className={(live_order?.type === type_order.commande) ? styles_DropdownMenu.selected : ''} id={type_order.commande}>
               <FontAwesomeIcon icon={faGemProLight} />
               {trans('?Commande')}
            </li>
            <li className={(live_order?.type === type_order.implant) ? styles_DropdownMenu.selected : ''} id={type_order.implant}>
               <FontAwesomeIcon icon={faShelvesProLight} />
               {trans('?Implantation')}
            </li>
            <li className={(live_order?.type === type_order.confie) ? styles_DropdownMenu.selected : ''} id={type_order.confie}>
               <FontAwesomeIcon icon={faHandHoldingBoxProLight} />
               {trans('?Confié')}
            </li>
            <li className={(live_order?.type === type_order.devis) ? styles_DropdownMenu.selected : ''} id={type_order.devis}>
               <FontAwesomeIcon icon={faFilePenProLight} />
               {trans('?Devis')}
            </li>
         </DropdownMenu>
      )
   }, [live_order, onSelectOrderType, trans, type_order])

   function doNothing(evt) {
      // console.log('doNothing')
      evt.stopPropagation();
      setShowDropdownMenuGlobal(false);
      setIsAddressPickerVisible(false);
      setIsMenuOrderTypeVisible(false);
      setShowDropdownMenuOnLineNum(null);
   }

   function toogleShowDropdownMenuGlobal(evt) {
      evt.stopPropagation();
      setShowDropdownMenuGlobal(!showDropdownMenuGlobal);
      setIsAddressPickerVisible(false);
      setIsMenuOrderTypeVisible(false);
      setShowDropdownMenuOnLineNum(null);
   }

   function toggleShowDropdownMenuOrderType(evt) {
      evt.stopPropagation();
      setIsAddressPickerVisible(false);
      setShowDropdownMenuGlobal(false);
      setIsMenuOrderTypeVisible(!isMenuOrderTypeVisible);
   }

   function toggleShowAddressPicker(evt) {
      evt.stopPropagation();
      setIsAddressPickerVisible(!isAddressPickerVisible);
      setShowDropdownMenuGlobal(false);
      setIsMenuOrderTypeVisible(false);
   }

   function hideQtyPicker() {
      setIsQtyPickerVisible(false)
   }

   async function onConfirmOK() {
      setIsConfirmVisible(false)
      if (confirmType === confirm_type.conf_delete_line) {
         console.log(editedModel, editedColor, editedSize);
         deleteSingleLineInOrder(live_order.id, editedLineNum)
      } else if (confirmType === confirm_type.conf_delete_order) {
         await deleteOrder(live_order.id)
         navigate('/orders', { replace: false })
      }
      setConfirmType(null)
   }

   function onConfirmCancel() {
      setConfirmType(null)
      setIsConfirmVisible(false)
   }

   // Si multi === false, on remplace la ligne éditée, ça permet de changer éventuellement couleur et taille
   // Si multi === true, ça fonctionne aussi mais automatiquement
   function updateLines(model, multi, variations) {
      console.log('::VARIATIONS', model, multi ? 'MULTI' : 'MONO', variations);
      if (multi) {
         // En multi on envoie l'intégralité en CreateOrUpdate. Les anciennes valeurs modifiées ou absentes seront modifiées/supprimées auto
         createOrUpdateLinesInOrder(live_order.id,
            variations.map((an_item) => {
               const { color, size, qty } = an_item
               const price = getCessPriceForModelColorSize(model, color, size, region)?.price ?? 0

               return {
                  "sku": makeSKU(model, color, size),
                  "qty": qty,
                  "price": price
               }
            })
         )
      } else {
         // En mono :
         // - Si la couleur ou la taille a changé, on envoie une qté = 0 pour supprimer cette ligne
         // - Et on envoie une ligne avec la nouvelle quantité pour la couleur+taille choisie dans le Picker.
         //   Si la couleur et la taille n'ont pas changé, ça fera donc un remplacement.
         // Mode mono, variations est forcément 1 seul objet
         const single = variations[0]

         const { color, size, qty } = single
         console.log(`Single: ${qty} X ${color}-${size}`);
         console.log(`Edited: ${editedColor}-${editedSize}`);
         if ((single.color === editedColor) && (single.size === editedSize)) {
            // Juste modif qté
            // console.log('Remplacement');
            createOrUpdateLinesInOrder(live_order.id, [{
               "sku": makeSKU(model, color, size),
               "qty": qty
            }], lastSelectedLineNum)
         } else {
            // Changement taille ou couleur + qté
            // console.log('Autre color/size');
            const price = getCessPriceForModelColorSize(model, color, size, region)?.price ?? 0

            createOrUpdateLinesInOrder(live_order.id, [
               {  // On supprime l'ancien
                  "sku": makeSKU(model, editedColor, editedSize),
                  "qty": 0
               },
               {  // On crée un nv
                  "sku": makeSKU(model, color, size),
                  "qty": qty,
                  "price": price
               }
            ])
         }
      }
   }

   function handleOrderDateSelect(val) {
      console.log(moment(val).format('YYYY-MM-DD'));
      updateOrder(live_order.id, {
         "date": moment(val).format('YYYY-MM-DD')
      })
   }

   function handleDeliveryDateSelect(val) {
      console.log(moment(val).format('YYYY-MM-DD'));
      updateOrder(live_order.id, {
         "delivery": moment(val).format('YYYY-MM-DD')
      })
   }

   useEffect(() => {
      setKeepSearchBarAccessible(false)
      setIsDarkLayerVisible(isQtyPickerVisible || isCommentEditorVisible || isAddressPickerVisible || isLineCommentEditorVisible || isContremarqueEditorVisible || isMailAddressPickerVisible)
   }, [isCommentEditorVisible, isLineCommentEditorVisible, isContremarqueEditorVisible, isQtyPickerVisible, setIsDarkLayerVisible, setKeepSearchBarAccessible, isAddressPickerVisible, isMailAddressPickerVisible])

   useEffect(() => {
      if (!isDarkLayerVisible) {
         setIsQtyPickerVisible(false)
      }
   }, [isDarkLayerVisible])


   const onEditContremarque = useCallback(() => {
      if (!closeAllDropDowns()) {
         setIsContremarqueEditorVisible(true)
      }
   }, [closeAllDropDowns])

   function onCancelEditContremarque() {
      setIsContremarqueEditorVisible(false)
   }

   function onValidateEditContremarque(text) {
      setIsContremarqueEditorVisible(false)
      updateOrder(live_order.id, {
         "mark": text
      })
   }

   const onEditComment = useCallback(() => {
      if (!closeAllDropDowns()) {
         setIsCommentEditorVisible(true)
      }
   }, [closeAllDropDowns])

   function onCancelEditComment() {
      setIsCommentEditorVisible(false)
   }

   function onValidateEditComment(text) {
      setIsCommentEditorVisible(false)
      updateOrder(live_order.id, {
         "comment": text
      })
   }

   function onCancelPickMailAddress() {
      setIsMailAddressPickerVisible(false)
   }

   function onValidatePickMailAddress(arr_emails, message) {
      console.log(arr_emails)
      setIsMailAddressPickerVisible(false)

      awPushPDF('CDE', live_order, arr_emails, message)
   }

   function goOrdersPage() {
      navigate('/orders', { replace: true })
   }

   function goCustomer() {
      navigate(`/customer-detail?id=${live_order.customer_id}`, { replace: true })
   }

   function leaveOrder() {
      setCurOrder(null)
   }

   function cancelSignature() {
      setIsSignatureVisible(false)
   }

   function okSignature(sig) {
      setIsSignatureVisible(false)
      updateOrder(live_order?.id, {
         signature: sig
      })
   }

   // console.log('>>>LIVE_ORDER', live_order);

   return (
      <Fragment>
         <Menu page="orders" />
         <div className={styles.order_detail} onClick={doNothing}>
            {searchBar}
            <div className={styles.content}>
               <div className={styles.header_bg}>
                  <div className={styles.header}>
                     <div className={styles.order_path}>
                        <FontAwesomeIcon icon={faFileLines} />
                        <Link onClick={goOrdersPage}>{trans('?Commande', true)}</Link>
                        <FontAwesomeIcon icon={faAngleRight} />
                        <Link onClick={goCustomer}>{customer?.name}</Link>
                        <FontAwesomeIcon icon={faAngleRight} />
                        <span># {live_order?.id}</span>
                        {live_order?.signature && <div className={styles.order_signed}>
                           <FontAwesomeIcon icon={faSignatureBold} />
                        </div>}
                        {!isReadOnly &&
                           <>
                              <div className={styles.sync_status}>
                                 {(live_order?.status !== order_status.new) && (live_order?.status !== order_status.locked4sync) && <FontAwesomeIcon className={styles.green} icon={faCloud} />}
                                 {(live_order?.status === order_status.locked4sync) && <FontAwesomeIcon className={styles.orange} icon={faCloudXmark} />}
                              </div>
                              <div className={styles.active_order}>
                                 {(curOrder === live_order?.id) &&
                                    <Button small secondary red onClick={leaveOrder}>{trans("?Quitter commande")}</Button>
                                 }
                                 {(curOrder !== live_order?.id) &&
                                    <Button small onClick={makeActive}>{trans("?Sélectionner")}</Button>
                                 }
                              </div>
                           </>
                        }
                        {isReadOnly && (live_order?.status !== order_status.done_m3) &&
                           <>
                              <div className={styles.sync_status}>
                                 {(live_order?.status !== order_status.new) && (live_order?.status !== order_status.deleted) && (live_order?.status !== order_status.locked4sync) && <FontAwesomeIcon className={styles.green} icon={faCloud} />}
                                 {(live_order?.status === order_status.locked4sync) && <FontAwesomeIcon className={styles.orange} icon={faCloudXmark} />}
                                 {(live_order?.status === order_status.deleted) && <FontAwesomeIcon className={styles.red} icon={faTrashXmark} />}
                              </div>
                           </>
                        }
                     </div>
                     <div className={styles.order_header}>
                        <div className={styles.order_type} onClick={(evt) => { if (!isReadOnly && isSelected) toggleShowDropdownMenuOrderType(evt) }}>
                           {(live_order?.type === type_order.commande) && <FontAwesomeIcon icon={faGemProSolid} />}
                           {(live_order?.type === type_order.implant) && <FontAwesomeIcon icon={faShelves} />}
                           {(live_order?.type === type_order.confie) && <FontAwesomeIcon icon={faHandHoldingBox} />}
                           {(live_order?.type === type_order.devis) && <FontAwesomeIcon icon={faFilePen} />}
                           <span>{live_order?.type}</span>
                           {isMenuOrderTypeVisible && dropdownMenuOrderType}
                        </div>
                        <div className={styles.order_id}>
                           <span>{trans('?N° Cde')}</span>
                           <span>{live_order?.id}</span>
                        </div>
                        <div className={styles.order_mark} onClick={() => { if (!isReadOnly && isSelected) onEditContremarque() }}>
                           <span>
                              {trans('?Contremarque')}
                              {!isReadOnly && isSelected && <FontAwesomeIcon icon={faPenToSquare} />}
                           </span>
                           <span>{live_order?.mark ?? ''}</span>
                        </div>
                        <div className={styles.order_date}>
                           <span>
                              {trans('?Date')}
                              {!isReadOnly && isSelected && <FontAwesomeIcon icon={faPenToSquare} />}
                           </span>
                           {(isReadOnly || !isSelected) &&
                              <span>{moment(live_order?.date).format('YYYY-MM-DD')}</span>
                           }
                           {!isReadOnly && isSelected &&
                              <span>
                                 <DatePicker
                                    selected={moment(live_order?.date).toDate()}
                                    locale={(curLang === 'fr') ? 'fr' : 'en-US'}
                                    showWeekNumbers={showWeeksNum}
                                    calendarStartDay={1} // forcer début de semaine = Lundi
                                    onSelect={handleOrderDateSelect} //when day is clicked
                                    onChange={/*handleDateChange*/() => { }} //only when value has changed
                                    customInput={<CustomDate text={live_order?.date} />}
                                 />
                              </span>
                           }
                        </div>
                        <div className={styles.order_delivery}>
                           <span>
                              {trans('?Livraison')}
                              {!isReadOnly && isSelected && <FontAwesomeIcon icon={faPenToSquare} />}
                           </span>
                           {(isReadOnly || !isSelected) &&
                              <span>{moment(live_order?.delivery).format('YYYY-MM-DD')}</span>
                           }
                           {!isReadOnly && isSelected &&
                              <span>
                                 <DatePicker
                                    selected={moment(live_order?.delivery).toDate()}
                                    locale={(curLang === 'fr') ? 'fr' : 'en-US'}
                                    showWeekNumbers={showWeeksNum}
                                    calendarStartDay={1} // forcer début de semaine = Lundi
                                    minDate={moment(live_order?.date).add(1, 'day').toDate()}
                                    onSelect={handleDeliveryDateSelect} //when day is clicked
                                    onChange={/*handleDateChange*/() => { }} //only when value has changed
                                    customInput={<CustomDate text={live_order?.delivery} />}
                                 />
                              </span>
                           }
                        </div>
                        {!isReadOnly &&
                           <div className={styles.order_addresses} onClick={(evt) => { if (isSelected) toggleShowAddressPicker(evt) }}>
                              <span>{trans('?Adresse', live_order?.type !== type_order.confie)}{!isReadOnly && isSelected && <FontAwesomeIcon icon={faPenToSquare} />}</span>
                              <div>
                                 <span className={!live_order?.addresses?.jewels ? styles.disabled : ''}><FontAwesomeIcon icon={faGem} /></span>
                                 {live_order?.type !== type_order.confie &&
                                    <span className={!live_order?.addresses?.ecrins ? styles.disabled : ''}><FontAwesomeIcon icon={faBox} /></span>
                                 }
                              </div>
                           </div>
                        }
                        <div className={styles.order_total}>
                           <span>{(itemsCountForOrder > 0) ? `${formatNumber(itemsCountForOrder)} ${trans("?article", itemsCountForOrder)}` : trans('?Total')}</span>
                           <span>
                              {formatCESPrice(totalForOrder, currency)}
                           </span>
                        </div>
                        {isSelected &&
                           <Button secondary onClick={toogleShowDropdownMenuGlobal} >
                              {trans('?Action', true)}
                              {showDropdownMenuGlobal && dropdownMenuGlobal}
                           </Button>
                        }
                        {/* <div className={styles.btn_actions} onClick={toogleShowDropdownMenuGlobal} >
                           <FontAwesomeIcon icon={faGear} />
                           {showDropdownMenuGlobal && dropdownMenuGlobal}
                        </div> */}
                     </div>
                     <div className={styles.order_comment} onClick={() => { if (!isReadOnly && isSelected) onEditComment() }}>
                        <span>
                           {trans('?Commentaire')}
                           {!isReadOnly && isSelected && <FontAwesomeIcon icon={faPenToSquare} />}
                        </span>
                        <span>{live_order?.comment}</span>
                     </div>
                  </div>
               </div>
               <div className={styles.detail}>
                  <table>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>{trans('?Modèle')}</th>
                           <th>{trans('?Couleur')}</th>
                           <th>{trans('?Taille')}</th>
                           <th>{trans('?Libellé')}</th>
                           <th>
                              {trans('?Commentaire')}
                              {!isReadOnly && isSelected && <FontAwesomeIcon icon={faPenToSquare} />}
                           </th>
                           <th>{trans('?Qté')}</th>
                           <th>{trans('?À livrer')}</th>
                           <th>{trans('?Prix')}</th>
                           {!isReadOnly && isSelected && <th></th>/* Actions*/}
                        </tr>
                     </thead>
                     <tbody>{lines}</tbody>
                  </table>
               </div>
               {/* <div className={styles.footer_bg}>
                  <div className={styles.footer}>
                     <span>{trans('?Affichage de')} xxxx </span>
                  </div>
               </div> */}
            </div>
         </div>
         {isQtyPickerVisible && (
            <QtyPicker customer={live_order?.customer_id} model={editedModel} color={editedColor} size={editedSize} multi={editedAllVariations} qty={editedQty} onHide={hideQtyPicker} onUpdate={updateLines} />
         )}
         {isLineCommentEditorVisible && (
            <CommentEditor title={trans('?Commentaire ligne')} icon={<FontAwesomeIcon icon={faMemo} />} value={editedLineComment} onCancel={onCancelEditLineComment} onValidate={onValidateEditLineComment} top={'20%'} minWidth={'30%'} maxWidth={'50%'} lines={5} maxLength={200} />
         )}
         {isCommentEditorVisible && (
            <CommentEditor title={trans('?Commentaire')} icon={<FontAwesomeIcon icon={faMemo} />} value={live_order?.comment} onCancel={onCancelEditComment} onValidate={onValidateEditComment} top={'20%'} minWidth={'30%'} maxWidth={'50%'} lines={5} maxLength={200} />
         )}
         {isMailAddressPickerVisible && (
            <MailAddressPicker customer={live_order?.customer_id} onCancel={onCancelPickMailAddress} onValidate={onValidatePickMailAddress} top={'20%'} minWidth={'30%'} maxWidth={'50%'} />
         )}

         {isAddressPickerVisible && (
            <AddressPicker title={trans('?Adresse de livraison', live_order?.type !== type_order.confie)} icon={<FontAwesomeIcon icon={faMemo} />} order={live_order} onClose={() => setIsAddressPickerVisible(false)} top={'20%'} />
         )}
         {isContremarqueEditorVisible && (
            <CommentEditor title={trans('?Contremarque')} icon={<FontAwesomeIcon icon={faTag} />} value={live_order?.mark} onCancel={onCancelEditContremarque} onValidate={onValidateEditContremarque} top={'20%'} minWidth={'30%'} maxWidth={'50%'} lines={1} maxLength={20} />
         )}
         {isConfirmVisible && (
            <Confirm yesno onOK={onConfirmOK} onCancel={onConfirmCancel} title={trans("?Suppression")} text={trans(confirmType === confirm_type.conf_delete_line ? "?confirmer_suppression_ligne" : "?confirmer_suppression_bdc")} />
         )}
         {isSignatureVisible && <Signature onCancel={cancelSignature} onOK={okSignature} sig={live_order?.signature} />}
         <ToastContainer />
      </Fragment>
   );
}

export default OrderDetail;
