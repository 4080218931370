const frTrans = {
   "?Français": "Français",
   "?Anglais": "Anglais",
   "?turn_landscape": "Veuillez tourner votre appareil en mode \"Paysage\" SVP.",
   "?Veuillez vous identifier": "Veuillez vous identifier",
   "?Serveur": "Serveur",
   "?Projet": "Projet",
   "?Identifiant": "Identifiant",
   "?Mot de passe": "Mot de passe",
   "?Votre mot de passe": "Votre mot de passe",
   "?Se souvenir de moi": "Se souvenir de moi",
   "?Mot de passe oublié": "Mot de passe oublié",
   "?S'identifier": "S'identifier",
   "?Gamme": "Gamme|Gammes",
   "?Par gamme": "Par gamme",
   "?Collection": "Collection|Collections",
   "?Par collection": "Par collection",
   "?Famille": "Famille|Familles",
   "?Par famille": "Par famille",
   "?Porté": "Porté|Portés",
   "?Par porté": "Par porté",
   "?Couleur": "Couleur|Couleurs",
   "?Taille": "Taille",
   "?Quantité": "Quantité",
   "?Dashboard": "Dashboard",
   "?Accueil": "Accueil",
   "?Catalogue": "Catalogue",
   "?Client": "Client|Clients",
   "?Paramètres": "Paramètres",
   "?Langue": "Langue",
   "?Tarif": "Tarif|Tarifs",
   "?Autre": "Autre|Autres",
   "?Commandes en cours": "Commandes en cours",
   "?Nouvelle commande": "Nouvelle commande",
   "?Retour au catalogue": "Retour au catalogue",
   "?Prix": "Prix",
   "?Poids": "Poids",
   "?Poids diamants": "Poids diamants",
   "?Diamants": "Diamants",
   "?Ecrin": "Ecrin|Ecrins",
   "?Bijou": "Bijou|Bijoux",
   "?À partir de": "À partir de",
   "?QTÉ": "QTÉ",
   "?Qté": "Qté",
   "?pds_g": "g",
   "?pds_ct": "ct",
   "?Sélecteur de quantité": "Sélecteur de quantité",
   "?Vérifier disponibilité": "Vérifier disponibilité",
   // "EUR": "€,R",     // Placer à droite
   // "USD": "$,L",     // Placer à gauche
   // "HKD": "HKD,L",   // Placer à gauche
   // "SGD": "SG$,L",   // Placer à gauche
   // "AUD": "AU$,L",   // Placer à gauche
   // "JPY": "¥,L",     // Placer à gauche
   // "WON": "₩,L",     // Placer à gauche
   // "CAD": "CA$,L",   // Placer à gauche
   // "CNY": "CNY,L",   // Placer à gauche
   // "DKK": "DKK,L",   // Placer à gauche
   // "CHF": "CHF,L",   // Placer à gauche
   // "MOP": "MOP,L",   // Placer à gauche
   // "MAD": "MAD,L",   // Placer à gauche
   // "NOK": "NOK,L",   // Placer à gauche
   // "SEK": "SEK,L",   // Placer à gauche
   // "GBP": "£,L",   // Placer à gauche
   "?Saisie multiple": "Saisie multiple",
   "?Disponible": "Disponible",
   "?Indisponible": "Indisponible",
   "?Non communiqué": "Non communiqué",
   "?Ajouter à la commande": "Ajouter à la commande",
   "?Modifier la commande": "Modifier la commande",
   "?Aucune commande sélectionnée": "Aucune commande sélectionnée",
   "?Aucun client sélectionné": "Aucun client sélectionné",
   "?Annuler": "Annuler",
   "?Non": "Non",
   "?OK": "OK",
   "?Oui": "Oui",
   "?Purger le cache": "Purger le cache",
   "?Purger les tarifs": "Purger les tarifs",
   "?x_sur_y": "sur",
   "?Filtre": "Filtre|Filtres",
   "?Appliquer": "Appliquer",
   "?Se déconnecter": "Se déconnecter",
   "?NC": "NC",
   "?Réinitialiser les préférences": "Réinitialiser les préférences",
   "?warn_expert_mode": "ATTENTION, en mode Expert ne changez rien sans y être invité par le service technique.",
   "?Repasser en mode Normal": "Repasser en mode Normal",
   "?Passer en mode Expert": "Passer en mode Expert",
   "?Format d'heure": "Format d'heure",
   "?Format de date": "Format de date",
   "?Priorité des images": "Priorité des images",
   "?by Messika": "by Messika",
   "?Interne": "Interne",
   "?DD-MM-YYYY": "JJ-MM-AAAA",
   "?MM-DD-YYYY": "MM-JJ-AAAA",
   "?YYYY-MM-DD": "AAAA-MM-JJ",
   "?YYYY-DD-MM": "AAAA-JJ-MM",
   "?min_length": "Longueur mini avant de lancer le filtrage",
   "?Fermeture popups": "Fermeture popups",
   "?Borneur de prix": "Borneur de prix",
   "?Bouton seulement": "Bouton seulement",
   "?Partout": "Partout",
   "?Gauche": "Gauche",
   "?Centré": "Centré",
   "?pas chargé": "pas chargé",
   "?chargé": "chargé|chargés",
   "?list_length_per_page": "Lignes par page dans les listes",
   "?Afficher ascenseurs": "Afficher ascenseurs",
   "?Paramètres régionaux": "Paramètres régionaux",
   "?Interface": "Interface",
   "?Position catalogue": "Affichage catalogue",
   "?max_buttons_on_lists": "Nb de boutons de page sur les listes",
   "?Quitter client": "Quitter client",
   "?Quitter commande": "Quitter commande",
   "?Sélectionner": "Sélectionner",
   "?Envoyer en PDF à...": "Envoyer en PDF à...",
   "?Dupliquer": "Dupliquer",
   "?Synchroniser": "Synchroniser",
   "?Sync": "Sync",
   "?Signature client": "Signature client",
   "?Toutes collections": "Toutes collections",
   "?Tous": "Tous",
   "?Toutes": "Toutes",
   "?Supprimer": "Supprimer",
   "?Modifier": "Modifier",
   "?N° Cde": "N° Cde",
   "?N°": "N°",
   "?Nom client": "Nom client",
   "?Téléphone": "Téléphone",
   "?Email": "Email|Emails",
   "?Date": "Date",
   "?Prix total": "Prix total",
   "?Total": "Total",
   "?Livraison": "Livraison",
   "?Action": "Action|Actions",
   "?Pièces": "Pièces",
   "?Modèle": "Modèle|Modèles",
   "?Commande": "Commande|Commandes",
   "?Libellé": "Libellé",
   "?Commentaire": "Commentaire",
   "?Commentaire ligne": "Commentaire ligne",
   "?Cess.": "Cess.",    // P. Cession
   "?Vider": "Vider",
   "?Trié par": "Trié par",
   "?Nom": "Nom",
   "?Affichage de": "Affichage de",
   "?Précédentes": "Précédentes",
   "?Suivantes": "Suivantes",
   "?par page": "par page",
   "?Entre": "Entre",
   "?et": "et",
   "?oui": "oui",
   "?non": "non",
   "?Retour": "Retour",
   "?Voir les tarifs": "Voir les tarifs",
   "?Masquer les tarifs": "Masquer les tarifs",
   "?Adresse": "Adresse|Adresses",
   "?Adresse de livraison": "Adresse de livraison|Adresses de livraison",
   "?Voir": "Voir",
   "?Créer une commande": "Créer une commande",
   "?Ecrire un mail": "Ecrire un mail",
   // "Voir les contacts": "Voir les contacts",
   // "Voir les adresses": "Voir les adresses",
   // "Voir les stats": "Voir les stats",
   "?Voir les infos": "Voir les infos",
   "?Afficher puce pour byMessika": "Afficher puce pour byMessika",
   "?Formater numéros de téléphone": "Formater numéros de téléphone",
   "?Code": "Code",
   "?Informations": "Informations",
   "?Stats": "Stats",
   "?Contact": "Contact|Contacts",
   "?N° de commande": "N° de commande",
   "?Supprimer toutes les commandes": "Supprimer toutes les commandes",
   "?Suppression": "Suppression",
   "?confirmer_suppression_bdc": "Souhaitez-vous réellement supprimer cette commande ?",
   "?confirmer_suppression_ligne": "Souhaitez-vous réellement supprimer cet article de votre commande ?",
   "?Valider": "Valider",
   "?Afficher les numéros de semaine (date picker)": "Afficher les numéros de semaine (date picker)",
   "?Quantité maxi par ligne de commande": "Quantité maxi par ligne de commande",
   "?Nouvelle commande %1 créée et activée": "Nouvelle commande %1 créée et activée",
   "?article": "article|articles",
   "?delay_show_cess": "Délai appui pour montrer les prix de cession (ms)",
   "?history_size": "Taille des historiques (commandes, clients...)",
   "?Garder historique ouvert": "Garder historique ouvert",
   "?reste x_sur_y cars": "Il reste %1 caractère sur %2|Il reste %1 caractères sur %2",
   "?Contremarque": "Contremarque",
   "?Implantation": "Implantation",
   "?Confié": "Confié|Confiés",
   "?Devis": "Devis",
   "?Paramétrer": "Paramétrer",
   "?Ajout de ligne par SKU": "Ajout de ligne par SKU",
   "?Recherche par SKU": "Recherche par SKU",
   "?Recherche dans le catalogue": "Recherche dans le catalogue",
   "?Recherche dans cette commande": "Recherche dans cette commande",
   "?item_X_added2order": "Article %1 ajouté à la commande|Article %1 ajouté en %2 exemplaires à la commande",
   "?Impossible d'ajouter ce SKU à la commande": "Impossible d'ajouter ce SKU à la commande",
   "?Type": "Type",
   "?max_tarifs_offline": "Nb maxi de tarifs préchargeable pour le mode déconnecté",
   "?Montrer l'état": "Montrer l'état",
   "?Seulement les tarifs cochés": "Seulement les tarifs sélectionnés",
   "?nb_tarifs_sel_sur_x": "%1 tarif sélectionné sur %2|%1 tarifs sélectionnés sur %2",
   "?dont_x_dispo_deco": "dont %1 disponible en mode déconnecté (maxi %2)|dont %1 disponibles en mode déconnecté (%2 max.)",
   "?Préchargé": "Préchargé",
   "?Mode déconnecté": "Mode déconnecté",
   "?Région": "Région",
   "?Listé": "Listé",
   "?Chargé": "Chargé",
   "?Chargé si besoin": "Chargé si besoin",
   "?Erreur de chargement": "Erreur de chargement",
   "?Non sélectionné": "Non sélectionné",
   "?substr_exclu_bout": "clusivité bout",    // Partie du texte "Exclusivité boutique"
   "?Préparation offline": "Préparation offline",
   "?Support": "Assistance",
   "?oMe_subject_support": "oMe - Demande d'assistance",
   "?Appeler": "Appeler",
   "?Préparer le mode offline": "Préparer le mode offline",
   "?Fermer": "Fermer",
   "?Terminé": "Terminé",
   "?Envoyer": "Envoyer",
   "?Importer": "Importer",
   "?Envoi en cours": "Envoi en cours",
   "?txt_sync_annulé": "Préparation offline annulée. Il manquera des images si vous perdez votre connexion Internet !",
   "?load_offline_assets": "Chargement des données pour travailler en mode déconnecté",
   "?Page d'assistance": "Page d'assistance",
   "?assist_wrong": "Si vous êtes arrivés ici par erreur :",
   "?assist_send_prefs": "Si le service IT vous le demande, envoyez vos données techniques à l'aide de ce bouton :",
   "?assist_got_prefs": "Une mise à jour de vos données techniques a été préparée par le service IT. Voulez-vous l'importer ?",
   "?Durée maxi d'inactivité": "Durée maxi d'inactivité",
   "?Fin de session dans": "Fin de session dans",
   "?Erreur de récupération des données": "Erreur de récupération des données",
   "?Erreur inconnue": "Erreur inconnue",
   "?Accès refusé": "Accès refusé",
   "?clean_db": "Si le service IT vous le demande, videz la base de données :",
   "?Vider la base": "Vider la base",
   "?Vider les commandes (Danger !)": "Vider les commandes (Danger !)",
   "?Global": "Global",
   "?Quitter cette page": "Quitter cette page",
   "?Tarif à télécharger": "Tarif à télécharger",
   "?Télécharger le tarif": "Télécharger le tarif",
   "?Téléchargement du tarif": "Téléchargement du tarif",
   // "?Le tarif du client n'est pas dans votre liste !": "Le tarif du client n'est pas dans votre liste !",
   "?Le tarif du client est inconnu !": "Le tarif du client est inconnu !",
   "?Voir dans le catalogue": "Voir dans le catalogue",
   "?Forcer synchronisation": "Forcer synchronisation",
   "?Traitée": "Traitée|Traitées",
   "?En cours oMe": "En cours oMe",
   "?Prix public": "Prix public",
   "?order_cloned": "Vous travaillez maintenant sur la commande dupliquée",
   "?Effacer": "Effacer",
   "?Détail": "Détail|Détails",
   "?Chercher un client": "Chercher un client",
   "?Chercher une commande": "Chercher une commande",
   "?Description": "Description",
   "?Adresse mail": "Adresse mail|Adresses mail",
   "?Message": "Message",
   "?Envoyer mail": "Envoyer mail",
   "?Documentation": "Documentation",
   "?Mot de passe unique": "Mot de passe unique",
   "?OTP_scan1": "Scannez ce QRCode ou collez-le dans votre logiciel OTP. Pour des raisons de sécurité, ne le conservez pas ailleurs !",
   "?OTP_scan2": "Puis saisissez ici le code à 6 chiffres généré par votre application OTP :",
   "?Code OTP": "Code OTP",
   "?6 chiffres": "6 chiffres",
   "?Masquer": "Masquer",
   "?Changer le mot de passe": "Changer le mot de passe",
   "?resetpwd_ok": "Mot de passe changé",
   "?resetpwd_nok": "Impossible de changer le mot de passe !",
   "?Mauvais code": "Mauvais code",
   "?Pièce": "Pièce|Pièces",
   "?Ventes": "Ventes",
   "?Reste à livrer": "Reste à livrer",
   "?Limite crédit": "Limite de crédit",
   "?hist_commandes": "Récupération de %1 commande :|Récupération de %1 commandes :",
   "?Vérification des commandes": "Vérification des commandes",
   "?chiffre_affaires": "Chiffre d'affaires",
   "?Mois": "Mois",
   "?À livrer": "À livrer",
}

export default frTrans