const enTrans = {
   "?Français": "French",
   "?Anglais": "English",
   "?turn_landscape": "Please turn your device in \"Landscape\" mode.",
   "?Veuillez vous identifier": "Please log in",
   "?Serveur": "Server",
   "?Projet": "Project",
   "?Identifiant": "Identifier",
   "?Mot de passe": "Password",
   "?Votre mot de passe": "Your password",
   "?Se souvenir de moi": "Remember me",
   "?Mot de passe oublié": "Lost password",
   "?S'identifier": "Log in",
   "?Gamme": "Range|Ranges",
   "?Par gamme": "By range",
   "?Collection": "Collection|Collections",
   "?Par collection": "By collection",
   "?Famille": "Family|Families",
   "?Par famille": "By family",
   "?Porté": "Style|Styles",
   "?Par porté": "By style",
   "?Couleur": "Color|Colors",
   "?Taille": "Size",
   "?Quantité": "Quantity",
   "?Dashboard": "Dashboard",
   "?Accueil": "Home",
   "?Catalogue": "Catalog",
   "?Client": "Customer|Customers",
   "?Paramètres": "Preferences",
   "?Langue": "Language",
   "?Tarif": "Tariff|Tariffs",
   "?Autre": "Other|Others",
   "?Commandes en cours": "Orders in progress",
   "?Nouvelle commande": "New order",
   "?Retour au catalogue": "Back to catalog",
   "?Prix": "Price",
   "?Poids": "Weight",
   "?Poids diamants": "Diamonds weight",
   "?Diamants": "Diamonds",
   "?Ecrin": "Box|Boxes",
   "?Bijou": "Jewel|Jewels",
   "?À partir de": "Starting from",
   "?QTÉ": "QTY",
   "?Qté": "Qty",
   "?pds_g": "g",
   "?pds_ct": "ct",
   "?Sélecteur de quantité": "Quantity picker",
   "?Vérifier disponibilité": "Check availability",
   // "EUR": "€,R",     // Placer à droite
   // "USD": "US$,L",     // Placer à gauche
   // "HKD": "HK$,L",   // Placer à gauche
   // "SGD": "SG$,L",   // Placer à gauche
   // "AUD": "AU$,L",   // Placer à gauche
   // "JPY": "¥,L",     // Placer à gauche
   // "WON": "₩,L",     // Placer à gauche
   // "CAD": "CA$,L",   // Placer à gauche
   // "CNY": "CNY,L",   // Placer à gauche
   // "DKK": "DKK,L",   // Placer à gauche
   // "CHF": "CHF,L",   // Placer à gauche
   // "MOP": "MOP,L",   // Placer à gauche
   // "MAD": "MAD,L",   // Placer à gauche
   // "NOK": "NOK,L",   // Placer à gauche
   // "SEK": "SEK,L",   // Placer à gauche
   // "GBP": "£,L",     // Placer à gauche
   "?Saisie multiple": "Multiple entries",
   "?Disponible": "Available",
   "?Indisponible": "Unavailable",
   "?Non communiqué": "?Undisclosed",
   "?Ajouter à la commande": "Add to order",
   "?Modifier la commande": "Modify order",
   "?Aucune commande sélectionnée": "No selected order",
   "?Aucun client sélectionné": "No selected customer",
   "?Annuler": "Cancel",
   "?Non": "No",
   "?OK": "OK",
   "?Oui": "Yes",
   "?Purger le cache": "Clear cache",
   "?Purger les tarifs": "Clear prices",
   "?x_sur_y": "of",
   "?Filtre": "Filter|Filters",
   "?Appliquer": "Apply",
   "?Se déconnecter": "Logout",
   "?NC": "NC",
   "?Réinitialiser les préférences": "Reset preferences",
   "?warn_expert_mode": "WARNING, in Expert mode don't change anything without being asked to by the technical staff.",
   "?Repasser en mode Normal": "Switch back to Normal mode",
   "?Passer en mode Expert": "Switch to Expert mode",
   "?Format d'heure": "Time format",
   "?Format de date": "Date format",
   "?Priorité des images": "Images priority",
   "?by Messika": "by Messika",
   "?Interne": "Internal",
   "?DD-MM-YYYY": "DD-MM-YYYY",
   "?MM-DD-YYYY": "MM-DD-YYYY",
   "?YYYY-MM-DD": "YYYY-MM-DD",
   "?YYYY-DD-MM": "YYYY-DD-MM",
   "?min_length": "Min length before filtering",
   "?Fermeture popups": "Close popups",
   "?Borneur de prix": "Price slider",
   "?Bouton seulement": "Button only",
   "?Partout": "Anywhere",
   "?Gauche": "Left",
   "?Centré": "Middle",
   "?pas chargé": "not loaded",
   "?chargé": "loaded",
   "?list_length_per_page": "Lines per page on lists",
   "?Afficher ascenseurs": "Show scrollbars",
   "?Paramètres régionaux": "Locales",
   "?Interface": "Interface",
   "?Position catalogue": "Catalog display",
   "?max_buttons_on_lists": "Max page buttons on lists",
   "?Quitter client": "Leave customer",
   "?Quitter commande": "Leave order",
   "?Sélectionner": "Select",
   "?Envoyer en PDF à...": "Send as PDF to...",
   "?Dupliquer": "Duplicate",
   "?Synchroniser": "Synchronize",
   "?Sync": "Sync",
   "?Signature client": "Customer signature",
   "?Toutes collections": "All collections",
   "?Tous": "All",
   "?Toutes": "All",
   "?Supprimer": "Delete",
   "?Modifier": "Edit",
   "?N° Cde": "Order ID",
   "?N°": "ID",
   "?Nom client": "Customer name",
   "?Téléphone": "Phone",
   "?Email": "Email|Emails",
   "?Date": "Date",
   "?Prix total": "Total price",
   "?Total": "Total",
   "?Livraison": "Delivery",
   "?Action": "Action|Actions",
   "?Pièces": "Items",
   "?Modèle": "Model|Models",
   "?Commande": "Order|Orders",
   "?Libellé": "Label",
   "?Commentaire": "Comment",
   "?Commentaire ligne": "Line comment",
   "?Cess.": "Cess.",    // P. Cession
   "?Vider": "Clear",
   "?Trié par": "Sort by",
   "?Nom": "Name",
   "?Affichage de": "Display",
   "?Précédentes": "Previous",
   "?Suivantes": "Next",
   "?par page": "per page",
   "?Entre": "Between",
   "?et": "and",
   "?oui": "yes",
   "?non": "no",
   "?Retour": "Back",
   "?Voir les tarifs": "Show tariffs",
   "?Masquer les tarifs": "Hide tariffs",
   "?Adresse": "Address|Addresses",
   "?Adresse de livraison": "Delivery address|Delivery addresses",
   "?Voir": "See",
   "?Créer une commande": "Create order",
   "?Ecrire un mmail": "Write a mail",
   // "Voir les contacts": "Show contacts",
   // "Voir les adresses": "Show addresses",
   // "Voir les stats": "Show stats",
   "?Voir les infos": "Show infos",
   "?Afficher puce pour byMessika": "Show bullet for byMessika",
   "?Formater numéros de téléphone": "Format phone numbers",
   "?Code": "Code",
   "?Informations": "Informations",
   "?Stats": "Stats",
   "?Contact": "Contact|Contacts",
   "?N° de commande": "Order ID",
   "?Supprimer toutes les commandes": "Delete all orders",
   "?Suppression": "Deletion",
   "?confirmer_suppression_bdc": "Do you really want to delete this order?",
   "?confirmer_suppression_ligne": "Do you really want to delete this item from your order?",
   "?Valider": "Validate",
   "?Afficher les numéros de semaine (date picker)": "Show week numbers (date picker)",
   "?Quantité maxi par ligne de commande": "Max quantity per order line",
   "?Nouvelle commande %1 créée": "New order %1 created and selected",
   "?article": "item|items",
   "?delay_show_cess": "Press delay to show cession prices (ms)",
   "?history_size": "History size (orders, customers...)",
   "?Garder historique ouvert": "Keep history open",
   "?reste x_sur_y cars": "Remaining %1 character over %2|Remaining %1 characters over %2",
   "?Contremarque": "Countermark",
   "?Implantation": "Implementation",
   "?Confié": "Consignment|Consignments",
   "?Devis": "Quotation",
   "?Paramétrer": "Setup",
   "?Ajout de ligne par SKU": "Add line from SKU",
   "?Recherche par SKU": "Search by SKU",
   "?Recherche dans le catalogue": "Search in catalog",
   "?Recherche dans cette commande": "Search in this order",
   "?item_X_added2order": "Item %1 added to order|%2 items %1 added to order",
   "?Impossible d'ajouter ce SKU à la commande": "Failed to add this SKU to order",
   "?Type": "Type",
   "?max_tarifs_offline": "Max numbre of tariffs loadable for offline mode",
   "?Montrer l'état": "Show status",
   "?Seulement les tarifs cochés": "Only selected tariffs",
   "?nb_tarifs_sel_sur_x": "%1 tariff selected over %2|%1 tariffs selected over %2",
   "?dont_x_dispo_deco": "%1 available in offline mode (%2 max.)",
   "?Préchargé": "Preloaded",
   "?Mode déconnecté": "Offline mode",
   "?Région": "Region",
   "?Listé": "Listed",
   "?Chargé": "Loaded",
   "?Chargé si besoin": "Loaded if needed",
   "?Erreur de chargement": "Loading error",
   "?Non sélectionné": "Not selected",
   "?substr_exclu_bout": "tique exclu",    // Partie du texte "Boutique exclusivity"
   "?Préparation offline": "Prepare offline",
   "?Support": "Support",
   "?oMe_subject_support": "oMe - Support request",
   "?Appeler": "Call",
   "?Préparer le mode offline": "Prepare offline mode",
   "?Fermer": "Close",
   "?Terminé": "Finished",
   "?Envoyer": "Send",
   "?Importer": "Import",
   "?Envoi en cours": "Sending in progress",
   "?txt_sync_annulé": "Offline preparing canceled. Some images will be missing if you loose your Internet connection!",
   "?load_offline_assets": "Loading data for offline mode",
   "?Page d'assistance": "Support page",
   "?assist_wrong": "If you've reached this page by mistake:",
   "?assist_send_prefs": "If the IT staff requested it, send your technical data using this button:",
   "?assist_got_prefs": "An update of your technical data has been prepared by the IT staff. Do you want to import it?",
   "?Durée maxi d'inactivité": "Max inactive duration",
   "?Fin de session dans": "Session end in",
   "?Erreur de récupération des données": "Failed to fetch data",
   "?Erreur inconnue": "Unknown error",
   "?Accès refusé": "Login denied",
   "?clean_db": "If the IT staff requested it, clean the database:",
   "?Vider la base": "Clean the database",
   "?Vider les commandes (Danger !)": "Clean orders (Danger!)",
   "?Global": "Global",
   "?Quitter cette page": "Leave this page",
   "?Tarif à télécharger": "Tariff to download",
   "?Télécharger le tarif": "Download tariff",
   "?Téléchargement du tarif": "Downloading tariff",
   // "?Le tarif du client n'est pas dans votre liste !": "The tariff for this client is not in your list!",
   "?Le tarif du client est inconnu !": "The tariff for this client is unknown!",
   "?Voir dans le catalogue": "View in catalog",
   "?Forcer synchronisation": "Force syncing",
   "?Traitée": "Processed",
   "?En cours oMe": "In progress oMe",
   "?Prix public": "Public price",
   "?order_cloned": "You're now working on the duplicated order",
   "?Effacer": "Clean",
   "?Détail": "Detail|Details",
   "?Chercher un client": "Search a customer",
   "?Chercher une commande": "Search an order",
   "?Description": "Description",
   "?Adresse mail": "Mail address|Mail addresses",
   "?Message": "Message",
   "?Envoyer mail": "Send mail",
   "?Documentation": "Documentation",
   "?Mot de passe unique": "One time password",
   "?OTP_scan1": "Scan this QRCode or copy-paste the code in your OTP application. For safty reasons, do not keep it anywhere else.",
   "?OTP_scan2": "Then enter the 6-digit code generated by your OTP application:",
   "?Code OTP": "OTP code",
   "?6 chiffres": "6 digits",
   "?Masquer": "Hide",
   "?Changer le mot de passe": "Set new password",
   "?resetpwd_ok": "Password changed",
   "?resetpwd_nok": "Impossible to change password!",
   "?Mauvais code": "Wrong code",
   "?Pièce": "Item|Items",
   "?Ventes": "Sales",
   "?Reste à livrer": "Remaining delivery",
   "?Limite crédit": "Credit limit",
   "?hist_commandes": "Fetching %1 order:|Fetching %1 orders:",
   "?Vérification des commandes": "Checking orders",
   "?chiffre_affaires": "Business sales",
   "?Mois": "Month",
   "?À livrer": "Remaining",
}

export default enTrans