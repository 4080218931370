import { useCallback } from "react"
import usePrefStore from "../stores/prefs"
// import useLogger from "./useLogger"
import useTranslation from "./useTranslation"
import useModelsStore from "../stores/models"
import { GlobalContext } from '../Contexts/GlobalContext';
import useSalesStore from "../stores/sales";
import axios from 'axios'

// Aussi dans package.json
const version = "0.99.28-7"

const versionDate = "2024-09-13"
const copyright = "© MESSIKA"
const devRef = "Conception et développement : Gildas QUINIOU (SAS Big Papoo)"
const devRefShort = "Concept. & dével. : Gildas QUINIOU"

const dynamicCacheName = 'site-dynamic';
const assetsCacheName = 'assets';
const fontsCacheName = 'fonts';

const mailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/

const useHelpers = () => {
   const { trans, lang } = useTranslation()
   // const { debug } = useLogger()
   // const curCurrency = usePrefStore((state) => state.curCurrency)
   // const dateFormat = usePrefStore((state) => state.dateFormat)
   // const timeFormat = usePrefStore((state) => state.timeFormat)
   // const curLang = usePrefStore((state) => state.currentLang)
   // const curTarifCustomer = usePrefStore((state) => state.curTarifCustomer);
   // const curTarifRegion = usePrefStore((state) => state.curTarifRegion);
   const { dateFormat, timeFormat, currentLang: curLang, curTarifCustomer, curTarifRegion, pwaApiEndpoint, pwaApiKey, udid } = usePrefStore();
   // const devises = useModelsStore((state) => state.devises)
   const { devises } = useModelsStore()
   // const countries = useSalesStore((state) => state.countries)
   const { countries } = useSalesStore()
   //
   const { curTarif } = { ...GlobalContext() }     // Pour prévenir d'un pb de déstructuration si NULL

   // val peut être String, Number (int ou float)
   // prec est la précision maxi après la virgule
   // si fill vaut true, des zéros sont ajoutés après les chiffres après la virgule si nécessaire (prec doit être > 0)
   const formatNumber = useCallback((val, prec = 0, fill = false) => {
      let tmp = val
      let minimumFractionDigits = (fill ? prec : 0)

      // log(val, tmp);

      if (typeof val === "string") {
         tmp = parseFloat(val)
      }
      if (typeof tmp === "number") {
         // log("ICI...", lang, val)
         // log(tmp.toLocaleString(lang, { minimumFractionDigits, maximumFractionDigits: prec }))
         return tmp.toLocaleString(lang, { minimumFractionDigits, maximumFractionDigits: prec }).replace(/[\u202f]/g, " ") // On remplace les espaces insécables par de vrais espaces.
      } else {
         return "?" + tmp
      }
   }, [lang])

   // Idem FormatNumber mais si la valeur est null ou -1, on retourne NC
   const formatNumberWithNC = useCallback((val, prec = 0, fill = false) => {
      return (((val === null) || (val < 0)) ? trans("?NC") : formatNumber(val, prec, fill))
   }, [formatNumber, trans])

   // Si compact vaut true, on ne met pas d'espace entre le nombre et l'unité de poids
   const formatWeight = useCallback((val, compact = false) => {
      return formatNumber(val, 2) + (compact ? "" : " ") + trans("?pds_g")
   }, [formatNumber, trans])

   // Idem formatWeight mais si la valeur est null ou -1, on retourne NC
   const formatWeightWithNC = useCallback((val, compact = false) => {
      return (((val === null) || (val < 0)) ? trans("?NC") : formatWeight(val, compact))
   }, [formatWeight, trans])

   // Si compact vaut true, on ne met pas d'espace entre le nombre et l'unité de poids
   const formatDiamWeight = useCallback((val, compact = false) => {
      return formatNumber(val?.replace(',', '.'), 3) + (compact ? "" : " ") + trans("?pds_ct")
   }, [formatNumber, trans])

   // Idem formatDiamWeight mais si la valeur est null ou -1, on retourne NC
   const formatDiamWeightWithNC = useCallback((val, compact = false) => {
      return (((val === null) || (val < 0)) ? trans("?NC") : formatDiamWeight(val, compact))
   }, [formatDiamWeight, trans])

   // Si compact vaut true, on ne met pas d'espace entre le nombre et la devise. Si currency === null, on prend la devise par défaut
   const formatPrice = useCallback((val, currency = null, compact = false, add_km = null) => {
      let strCurrency = ""
      const lang = (curLang === 'en') ? 'en' : 'fr'
      let toLeft   // Placer la devise à droite ?
      if (currency === null) {
         if (curTarif) {
            strCurrency = (devises && devises[curTarif.other.ppu.currency]?.label[lang]) ?? curTarif.other.ppu.currency
            toLeft = (curTarif.other.ppu.currency !== 'EUR')
         } else {
            strCurrency = '🪲'   // Bug de devise...
            toLeft = true
         }
      } else {
         strCurrency = (devises && devises[currency]?.label[lang]) ?? currency
         toLeft = (currency !== 'EUR')
      }
      return (toLeft ? (strCurrency + (compact ? "" : " ")) : '') + formatNumber(val, 2) + (add_km ?? '') + (toLeft ? '' : ((compact ? "" : " ") + strCurrency))
   }, [curLang, curTarif, devises, formatNumber])

   // Idem formatPrice mais si la valeur est null ou -1, on retourne NC. Si currency === null, on prend la devise par défaut
   const formatPriceWithNC = useCallback((val, currency = null, compact = false) => {
      return (((val === null) || (val < 0)) ? trans("?NC") : formatPrice(val, currency, compact))
   }, [formatPrice, trans])

   const formatPriceKM = useCallback((val, currency, mini_for_km = 1000, decimals = 0) => {
      if (val < mini_for_km) {
         return formatPrice(val, currency)
      } else {
         let div1, km

         if (val < 1000000) {
            div1 = Math.pow(10, 3 - decimals)
            km = 'K'
         } else {
            div1 = Math.pow(10, 6 - decimals)
            km = 'M'
         }
         const div2 = Math.pow(10, decimals)
         // console.log(div1, div2, Math.floor(val / div1), Math.floor(val / div1) / div2);
         return formatPrice(Math.floor(val / div1) / div2, currency, false, km)
      }
   }, [formatPrice])

   const formatPPUPrice = useCallback((val, currency = null, compact = false) => {
      if (currency) {
         return formatPrice(val, currency, compact)
      } else if (curTarifCustomer) {
         return formatPrice(val, curTarifCustomer.other.ppu.currency, compact)
      } else if (curTarifRegion) {
         return formatPrice(val, curTarifRegion.other.ppu.currency, compact)
      } else {
         return null
      }
   }, [curTarifCustomer, curTarifRegion, formatPrice])

   const formatHJO_PPUPrice = useCallback((val, currency = null, compact = false) => {
      if (currency) {
         return formatPrice(val, currency, compact)
      } if (curTarifCustomer) {
         return formatPrice(val, curTarifCustomer.hjo.ppu.currency, compact)
      } else if (curTarifRegion) {
         return formatPrice(val, curTarifRegion.hjo.ppu.currency, compact)
      } else {
         return null
      }
   }, [curTarifCustomer, curTarifRegion, formatPrice])

   const formatCESPrice = useCallback((val, currency = null, compact = false) => {
      if (currency) {
         return formatPrice(val, currency, compact)
      } if (curTarifCustomer) {
         return formatPrice(val, curTarifCustomer.other.ces.currency, compact)
      } else if (curTarifRegion) {
         return formatPrice(val, curTarifRegion.other.ces.currency, compact)
      } else {
         return null
      }
   }, [curTarifCustomer, curTarifRegion, formatPrice])

   const formatHJO_CESPrice = useCallback((val, currency = null, compact = false) => {
      if (currency) {
         return formatPrice(val, currency, compact)
      } if (curTarifCustomer) {
         return formatPrice(val, curTarifCustomer.hjo.ces.currency, compact)
      } else if (curTarifRegion) {
         return formatPrice(val, curTarifRegion.hjo.ces.currency, compact)
      } else {
         return null
      }
   }, [curTarifCustomer, curTarifRegion, formatPrice])

   const formatPPUPriceWithNC = useCallback((val, currency = null, compact = false) => {
      if (currency) {
         return formatPriceWithNC(val, currency, compact)
      } if (curTarifCustomer) {
         return formatPriceWithNC(val, curTarifCustomer.other.ppu.currency, compact)
      } else if (curTarifRegion) {
         return formatPriceWithNC(val, curTarifRegion.other.ppu.currency, compact)
      } else {
         return null
      }
   }, [curTarifCustomer, curTarifRegion, formatPriceWithNC])

   const formatHJO_PPUPriceWithNC = useCallback((val, currency = null, compact = false) => {
      if (currency) {
         return formatPriceWithNC(val, currency, compact)
      } if (curTarifCustomer) {
         return formatPriceWithNC(val, curTarifCustomer.hjo.ppu.currency, compact)
      } else if (curTarifRegion) {
         return formatPriceWithNC(val, curTarifRegion.hjo.ppu.currency, compact)
      } else {
         return null
      }
   }, [curTarifCustomer, curTarifRegion, formatPriceWithNC])

   const formatCESPriceWithNC = useCallback((val, currency = null, compact = false) => {
      if (currency) {
         return formatPriceWithNC(val, currency, compact)
      } if (curTarifCustomer) {
         return formatPriceWithNC(val, curTarifCustomer.other.ces.currency, compact)
      } else if (curTarifRegion) {
         return formatPriceWithNC(val, curTarifRegion.other.ces.currency, compact)
      } else {
         return null
      }
   }, [curTarifCustomer, curTarifRegion, formatPriceWithNC])

   const formatHJO_CESPriceWithNC = useCallback((val, currency = null, compact = false) => {
      if (currency) {
         return formatPriceWithNC(val, currency, compact)
      } if (curTarifCustomer) {
         return formatPriceWithNC(val, curTarifCustomer.hjo.ces.currency, compact)
      } else if (curTarifRegion) {
         return formatPriceWithNC(val, curTarifRegion.hjo.ces.currency, compact)
      } else {
         return null
      }
   }, [curTarifCustomer, curTarifRegion, formatPriceWithNC])

   /**
    * @param {Date} date, la date en object Date
    * @param {string|null} format, Formats possibles ['dmy', 'mdy', 'ymd', 'ydm']. Si null, c'est la paramétrage choisi par l'utilisateur qui est utilisé
    */
   const formatDate = useCallback((date, format = null) => {
      if (!(date instanceof Date)) {
         return "??1"
      }
      const day = date.getDate().toLocaleString('fr', { minimumIntegerDigits: 2 })  // 'fr' : OK car c'est juste pour aff sur 2 digits
      const month = (date.getMonth() + 1).toLocaleString('fr', { minimumIntegerDigits: 2 })
      const year = date.getFullYear()
      if ((format ?? dateFormat) === 'dmy') {
         return day + '-' + month + '-' + year
      } else if ((format ?? dateFormat) === 'mdy') {
         return month + '-' + day + '-' + year
      } else if ((format ?? dateFormat) === 'ymd') {
         return year + '-' + month + '-' + day
      } else if ((format ?? dateFormat) === 'ydm') {
         return year + '-' + day + '-' + month
      } else {
         return "??2"
      }
   }, [dateFormat])

   /**
    * @param {Date} date, la date en object Date
    * @param {string|null} format, Formats possibles ['12', '24']. Si null, c'est la paramétrage choisi par l'utilisateur qui est utilisé
    */
   const formatTime = useCallback((date, format = null) => {
      if (!(date instanceof Date)) {
         return "??3"
      }
      const hours = date.getHours()
      const minutes = date.getMinutes().toLocaleString('fr', { minimumIntegerDigits: 2 })
      if ((format ?? timeFormat) === '12') {
         return (hours % 12).toLocaleString('fr', { minimumIntegerDigits: 2 }) + ':' + minutes + ' ' + (((hours / 12.0) > 1) ? "PM" : "AM")
      } else if ((format ?? timeFormat) === '24') {
         return hours.toLocaleString('fr', { minimumIntegerDigits: 2 }) + ':' + minutes
      } else {
         return "??4"
      }
   }, [timeFormat])

   /**
    * Calcul la valeur à un indice donné, sur une échelle linéaire, avec ou sans arrondi
    * Rappel : 0 <= num_step < steps
    */
   const calcValueFromLinearStep = useCallback((mini, maxi, steps, num_step, rounded = true) => {
      // log('calcLinearValueFromStep mini', mini, 'maxi', maxi, 'steps', steps, 'num_step', num_step)

      const rawValue = mini + (((maxi - mini) / (steps - 1)) * num_step)

      if (rounded) {
         return Math.round(rawValue, 0)
      } else {
         return rawValue
      }
   }, [])

   /**
    * Calcul de l'indice (arrondi) pour une valeur donnée entre 2 valeurs (min, max), sur une échelle linéaire
    * Rappel : indice retourné entre 0 et steps - 1
    */
   const calcLinearStepFromValue = useCallback((mini, maxi, value, steps) => {
      let deltaByStep

      // log('calcLinearStepFromValue : mini', mini, 'maxi', maxi, 'value', value)

      if (steps > 1) {
         deltaByStep = (maxi - mini) / (steps - 1)
      } else {
         deltaByStep = maxi - mini
      }
      const rawValue = (value - mini) / deltaByStep
      // log('calcLinearStepFromValue', rawValue, deltaByStep, mini, maxi, value, steps)
      return Math.round(rawValue, 0)
   }, [])

   /**
    * Trouve l'indice (dans un tableau) d'une valeur donnée, sans la dépasser
    * Rappel : indice retourné entre 0 et steps - 1
    */
   // const findStepFromValue = useCallback((arr, value) => {
   //    const ret = arr.findIndex((curVal) => {
   //       return (curVal >= value)
   //    })

   //    if (ret !== -1) {
   //       return ret
   //    } else {
   //       return arr.length - 1
   //    }
   // }, [])

   /**
    * Un filtre très "tricky" pour éviter l'affichage de JOI pour Joaillerie. Ca retourne JOA à la place.
    * Ca ne peut et ne doit être utiliser que pour des zones de texte, pas pour un usage en tant que
    * code/tag de la gamme JOI/Joaillerie !!!
    */
   const tweakJOI = useCallback((code) => {
      if (code === 'JOI') {
         return 'JOA'
      } else {
         return code
      }
   }, [])

   const getVersion = useCallback(() => {
      return version
   }, [])

   // const getSWVersion = useCallback(() => {
   //    return localStorage.getItem("sw_version");
   // }, [])

   const getVersionDate = useCallback(() => {
      return versionDate
   }, [])

   const getCopyright = useCallback(() => {
      return copyright
   }, [])

   const getDevRef = useCallback(() => {
      return devRef
   }, [])

   const getDevRefShort = useCallback(() => {
      return devRefShort
   }, [])

   // Reconstruit un style avec les propriétés trouvées dans props
   const inlineStyleFromProps = useCallback((props) => {
      const style = {}
      if (props.width !== undefined) {
         style["width"] = props.width
      }
      if (props.minWidth !== undefined) {
         style["minWidth"] = props.minWidth
      }
      if (props.maxWidth !== undefined) {
         style["maxWidth"] = props.maxWidth
      }
      if (props.height !== undefined) {
         style["height"] = props.height
      }
      if (props.minHeight !== undefined) {
         style["minHeight"] = props.minHeight
      }
      if (props.maxHeight !== undefined) {
         style["maxHeight"] = props.maxHeight
      }
      if (props.lineHeight !== undefined) {
         style["lineHeight"] = props.lineHeight
      }
      if (props.left !== undefined) {
         style["left"] = props.left
      }
      if (props.right !== undefined) {
         style["right"] = props.right
      }
      if (props.top !== undefined) {
         style["top"] = props.top
      }
      if (props.bottom !== undefined) {
         style["bottom"] = props.bottom
      }
      return style
   }, [])

   // Modifier aussi dans générateur PDF
   const buildAddress = useCallback((address) => {
      let addr1, addr2, addr3, zip, city, state, country_id, country
      const lang = (curLang === 'en') ? 'en' : 'fr'
      const ret = []

      addr1 = address?.addr1
      addr2 = address?.addr2
      addr3 = address?.addr3
      zip = address?.zip
      city = address?.city
      state = address?.state
      country_id = address?.country
      // console.log(country_id, countries);
      if (country_id && countries && countries[country_id]) {
         country = countries[country_id][lang] ?? country_id
      } else {
         country = country_id
      }
      if (addr1) {
         ret.push(addr1)
      }
      if (addr2) {
         ret.push(addr2)
      }
      if (addr3) {
         ret.push(addr3)
      }
      if (zip || city || country) {
         ret.push(`${zip ?? ''} ${city ?? ''}` + (state ? `, ${state}` : '') + `${country ? " -" : ""} ${country ?? ''}`)
      }
      // console.log('+++BuildAddr', ret);
      return ret
   }, [countries, curLang])

   const miscLogs = useCallback((title, logs) => {
      axios.post(`${pwaApiEndpoint}/misc_logs`, {
         udid,
         logs: [title, ...(Array.isArray(logs) ? logs.map(a_line => JSON.stringify(a_line)) : [logs])]
      }, {
         headers: { 'X-API-Key': pwaApiKey },
      }).then((data) => {
         console.log('DATA', data);
      }).catch(err => {
         console.log('ERR', err);
      })
   }, [pwaApiEndpoint, pwaApiKey, udid])

   const resetPassword = useCallback((otp_code, new_password) => {
      axios.post(`${pwaApiEndpoint}/reset_password`, {
         udid,
         otp_code,
         new_password
      }, {
         headers: { 'X-API-Key': pwaApiKey },
      }).then((data) => {
         console.log('DATA', data);
      }).catch(err => {
         console.log('ERR', err);
      })
   }, [pwaApiEndpoint, pwaApiKey, udid])

   /**
    * Utilisé qd une nv version est installée
    */
   const cleanCache = useCallback(() => {
      try {
         // const test=... Petit tweak (try...catch) pour éviter erreur de "cannot find variable caches" dans Safari
         // eslint-disable-next-line no-unused-vars
         const test = caches ?? true
         caches
            .keys()
            .then((keys) =>
               keys
                  .filter((key) => {
                     return ((key === assetsCacheName) || (key === fontsCacheName) || (key === dynamicCacheName))
                  })
                  .map((key) => {
                     console.log('Suppression du cache', key);
                     return caches.delete(key);
                  })
            );
      } catch (error) {
         console.log('Pas de cache!!!');
      }
   }, [])

   return { formatNumber, formatNumberWithNC, formatWeight, formatWeightWithNC, formatDiamWeight, formatDiamWeightWithNC, formatPrice, formatPriceWithNC, formatPriceKM, calcValueFromLinearStep, calcLinearStepFromValue/*, findStepFromValue*/, formatDate, formatTime, tweakJOI, getVersion, getVersionDate, getCopyright, getDevRef, getDevRefShort, inlineStyleFromProps, formatPPUPrice, formatCESPrice, formatHJO_PPUPrice, formatHJO_CESPrice, formatPPUPriceWithNC, formatCESPriceWithNC, formatHJO_PPUPriceWithNC, formatHJO_CESPriceWithNC, buildAddress, miscLogs, resetPassword, cleanCache, mailRegex }
}

export default useHelpers