import { Fragment, useCallback, useEffect, useState } from 'react'
import Menu from '../components/Menu/Menu'
import Stats from '../components/Stats'
// import Searchbar from '../components/Searchbar'    URGENCE TARIFS
import styles from './Main.module.scss'
// import useTranslation from '../Hooks/useTranslation'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';    // URGENCE TARIFS
import { faPersonDigging } from '@fortawesome/free-solid-svg-icons'; // URGENCE TARIFS

function Main() {
   // States mémo pour historique
   // States
   // const [searchText, setSearchText] = useState('')   URGENCE TARIFS
   // const [searchBar, setSearchBar] = useState(null)   URGENCE TARIFS
   // const [stats, setStats] = useState(null)           URGENCE TARIFS
   //
   // const { trans } = useTranslation()

   // const onSearchChange = useCallback((text) => {     URGENCE TARIFS
   //    setSearchText(text);
   // }, [])

   // useEffect(() => {                                  URGENCE TARIFS
   //    setSearchBar(<Searchbar onChange={onSearchChange} text={searchText} />)
   // }, [onSearchChange, searchText])

   // useEffect(() => {                                  URGENCE TARIFS
   //    setStats(<Stats />)
   // }, [])

   return (
      <Fragment>
         <Menu page="Main" />
         <div className={styles.main}>
            {/* {searchBar}   URGENCE TARIFS
            <div className={styles.content}>
               <div className={styles.header}>
                  <h1>
                     Bienvenue
                  </h1>
               </div>
               {stats}
            </div> */}
            <div className={styles.digging}>
               <FontAwesomeIcon icon={faPersonDigging} />
               <span>Cette page arrive bientôt...</span>
            </div>
         </div>
      </Fragment>
   )
}

export default Main
