import styles_Searchbar from '../components/Searchbar.module.scss';
import { Fragment, useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope as faEnvelopeSolid, faCircleInfo, faAddressBook, faLocationDot, faBagShopping as faBagShoppingSolid, faStore/*, faGear*/, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faBookmark } from '@fortawesome/pro-regular-svg-icons'
import { faUpToLine, faBagsShopping, faBuilding, faBox, faShelves, faGem, faHandHoldingBox, faFilePen, faCloud, faCloudXmark, faTrashXmark, faSignature, faUserTieHairLong, faFileLines } from '@fortawesome/pro-solid-svg-icons'
import { faBagShopping, faBadgeCheck, faEnvelope, faPenToSquare, faFilePdf, faClone, faCloudArrowUp, faEye, faTrashCan } from '@fortawesome/pro-light-svg-icons'
import { faCloudSlash } from '@fortawesome/pro-solid-svg-icons'
// import { faEnvelope, faBagShopping, faBadgeCheck } from '@fortawesome/pro-light-svg-icons'
import DropdownMenu from '../components/DropdownMenu'
import Menu from '../components/Menu/Menu'
import Searchbar from '../components/Searchbar'
import usePrefStore from '../stores/prefs'
import styles from './CustomerDetail.module.scss'
// import useHelpers from '../Hooks/useHelpers'
import useSales from '../Hooks/useSales'
import PagesButtons from '../components/PagesButtons'
// import GraphPie from '../components/Graph/GraphPie'
import useTranslation from '../Hooks/useTranslation'
import DropdownField from '../components/DropdownField'
import Button from '../components/Button'
import parsePhoneNumber from 'libphonenumber-js'
// import useStateWithHistory from '../Hooks/useStateWithHistory'
import { useSearchParams } from 'react-router-dom'
// import GraphLine from '../components/Graph/GraphLine'
import { useNavigate } from 'react-router-dom';
import useHelpers from '../Hooks/useHelpers'
import { useLiveQuery } from "dexie-react-hooks"
import { Checkbox, States as CheckboxStates } from '../components/Checkbox'
import Confirm from '../components/Confirm'
import { ToastContainer } from 'react-toastify'
import Stats from '../components/Stats'
import MailAddressPicker from '../components/MailAddressPicker';
import { GlobalContext } from '../Contexts/GlobalContext';
import { AppWriteContext } from '../Contexts/AppWriteContext'

const menu_items = {
   setActive: "active",
   doEdit: "edit",
   doSee: "see",
   doClone: "clone",
   doSync: "sync",
   doDelete: "delete",
   sendPdf: "pdf",
   writeMail: "writemail",
   createOrder: "neworder",
   // showAddresses: "showaddr",
   // showContacts: "showcont",
}

const tab_names = {
   info: "info",
   // stats: "stats",
   contacts: "contacts",
   addresses: "addresses",
   unsync: "unsync"
}

const search_type = {
   other_client: "other_client",
   order: "order",
}

function CustomerDetail() {
   const listPageLength = Number(usePrefStore((state) => state.listPageLength))
   const { setListPageLength, curCustomer, setCurCustomer, curOrder, setCurOrder, formatPhone, currentLang, searchModeInCustomer, setSearchModeInCustomer } = usePrefStore();
   const { setIsDarkLayerVisible } = { ...GlobalContext() }
   // States mémo pour historique
   // const [curFilter, setCurFilter] = useStateWithHistory('cust_cur_filter', null)   // null === Tous
   // const [curPage, setCurPage] = useStateWithHistory('cust_cur_page', 1)
   // const [searchText, setSearchText] = useStateWithHistory('cust_search_text', '')
   // States
   // const [isSearchFilterVisible, setIsSearchFilterVisible] = useState(false)
   const [searchText, setSearchText] = useState('')
   const [customer, setCustomer] = useState(null)
   const [selectedContact, setSelectedContact] = useState(null)
   const [searchBar, setSearchBar] = useState(null)
   const [contactsLines, setContactsLines] = useState(null)
   const [addressesLines, setAddressesLines] = useState(null)
   const [linesSyncOrders, setLinesSyncOrders] = useState(null) // Commandes sync affichées sur la page courante
   const [linesUnsyncOrders, setLinesUnsyncOrders] = useState(null) // Commande unsync affichés sur la page courante
   const [filteredSyncOrders, setFilteredSyncOrders] = useState(null) // Commandes sync de la page courante
   const [filteredUnsyncOrders, setFilteredUnsyncOrders] = useState(null) // Commandes unsync de la page courante
   const [curPageSyncOrders, setCurPageSyncOrders] = useState(1) // N° de la page courante des commandes sync
   const [curPageUnsyncOrders, setCurPageUnsyncOrders] = useState(1) // N° de la page courante des commandes unsync
   const [nbPagesSyncOrders, setNbPagesSyncOrders] = useState(1)
   const [nbPagesUnsyncOrders, setNbPagesUnsyncOrders] = useState(1)
   const [firstPageSyncOrders, setFirstPageSyncOrders] = useState(1) // 1ère page des commandes sync sur le composant de pages
   const [firstPageUnsyncOrders, setFirstPageUnsyncOrders] = useState(1)   // 1ère page des commandes unsync sur le composant de pages
   const [dropdownMenuContact, setDropdownMenuContact] = useState(null)
   const [pagesButtonsSyncOrders, setPagesButtonsSyncOrders] = useState(null)
   const [pagesButtonsUnsyncOrders, setPagesButtonsUnsyncOrders] = useState(null)
   const [dropdownMenuAddress, setDropdownMenuAddress] = useState(null)
   const [showDropdownMenuOnContactNum, setShowDropdownMenuOnContactNum] = useState(null)
   const [showDropdownMenuOnAddressNum, setShowDropdownMenuOnAddressNum] = useState(null)
   const [isItemsPerPageOpen, setIsItemsPerPageOpen] = useState(false)
   const [curTab, setCurTab] = useState()
   const [dropdownMenuOrders, setDropdownMenuOrders] = useState(null)
   const [showDropdownMenuOnOrderId, setShowDropdownMenuOnOrderId] = useState(null)
   const [lastPickedOrderId, setLastPickedOrderId] = useState(null)  // Pour envoi PDF
   const [selectedOrder, setSelectedOrder] = useState()
   const [isConfirmVisible, setIsConfirmVisible] = useState(false)
   const [isMailAddressPickerVisible, setIsMailAddressPickerVisible] = useState(false)
   const [deleteOrderId, setDeleteOrderId] = useState(null)
   const [stats, setStats] = useState(null)
   //
   const { getCustomerById, setCustomerIdAsCurrent, getContactsFromCustomerAsSortedArray, getAddressesFromCustomerAsSortedArray, type_address, tag_address, createOrder, makeTemplateOrder, getUnsyncOrdersForCustomerId, getSyncOrdersForCustomerId, order_status, type_order, calcTotalForOrder, getOrderById, setOrderIdAsCurrent, updateRecentOrders, updateOrder, cloneOrder, deleteOrder, canSyncOrder } = useSales()
   const { trans, lowtrans } = useTranslation()
   //
   const [searchParams] = useSearchParams()
   const { buildAddress, formatCESPrice } = useHelpers()
   const navigate = useNavigate()
   const { awPushPDF } = { ...AppWriteContext() }
   //
   const live_sync_orders = useLiveQuery(async () => await getSyncOrdersForCustomerId(customer?.id), [customer])
   const live_unsync_orders = useLiveQuery(async () => await getUnsyncOrdersForCustomerId(customer?.id), [customer])

   useEffect(() => {
      setStats(<Stats customer={customer?.id} />)
   }, [customer])

   useEffect(() => {
      setIsDarkLayerVisible(isMailAddressPickerVisible)
   }, [isMailAddressPickerVisible, setIsDarkLayerVisible])

   useEffect(() => {
      let tab = searchParams.get('tab')
      // if (tab === 'info') {   // TODO Supprimer quand on aura les infos des 2 prems tabs
      //    tab = 'contacts'
      // }
      const id = searchParams.get('id')

      setCurTab(tab ?? tab_names.unsync)  // URGENCE TARIFS
      // setCurTab(tab ?? tab_names.info) // URGENCE TARIFS

      setCustomer(getCustomerById(id))
   }, [getCustomerById, searchParams])

   // console.log('+++Customer', customer)

   const onSearch = useCallback((text) => {
      if (((curTab !== tab_names.sync) && (curTab !== tab_names.unsync)) || (searchModeInCustomer === search_type.other_client)) {
         navigate(`/customers?search=${encodeURIComponent(text)}`, { replace: true })
      }
   }, [curTab, navigate, searchModeInCustomer])

   const onSelectAddress = useCallback((id) => {
      if (id === menu_items.setActive) {
         setCustomerIdAsCurrent(customer.id)
         setShowDropdownMenuOnAddressNum(null)
      }
   }, [customer, setCustomerIdAsCurrent])

   const onSelectContact = useCallback((id) => {
      if (id === menu_items.setActive) {
         setCustomerIdAsCurrent(customer.id)
      } else if (id === menu_items.writeMail) {
         window.location = `mailto:${selectedContact.email}`
      }
      setShowDropdownMenuOnContactNum(null)
   }, [customer, selectedContact, setCustomerIdAsCurrent])

   const prepShowDropDown = useCallback(async (id) => {
      if (id !== null) {
         setShowDropdownMenuOnOrderId(id)
         setLastPickedOrderId(id)
         setSelectedOrder(await getOrderById(id))
      } else {
         setShowDropdownMenuOnOrderId(null)
         setSelectedOrder(null)
      }
   }, [getOrderById])

   const onSelectMenuOrder = useCallback((id) => {
      // console.log('ID>>', id, showDropdownMenuOnCustomerId);
      prepShowDropDown(null)  // On referme en mode async mais pas de pb
      if (id === menu_items.setActive) {
         setOrderIdAsCurrent(showDropdownMenuOnOrderId)
      } else if ((id === menu_items.doEdit) || (id === menu_items.doSee)) {
         updateRecentOrders(showDropdownMenuOnOrderId)
         navigate(`/order-detail?id=${showDropdownMenuOnOrderId}`, { replace: false })
      } else if (id === menu_items.doClone) {
         cloneOrder(showDropdownMenuOnOrderId)
         setCurTab(tab_names.unsync)
      } else if (id === menu_items.sendPdf) {
         setIsMailAddressPickerVisible(true)
      } else if (id === menu_items.doDelete) {
         setDeleteOrderId(showDropdownMenuOnOrderId)
         setIsConfirmVisible(true)
      } else if (id === menu_items.doSync) {
         updateOrder(showDropdownMenuOnOrderId, {
            status: order_status.locked4sync,   // On va essayer de la pousser sur AppWrite (c'est updateOrder qui s'en charge, elle va ensuite passer en locked)
            readonly: true,
         })
      } else if (id === menu_items.doSee) {
      }
   }, [cloneOrder, navigate, order_status.locked4sync, prepShowDropDown, setOrderIdAsCurrent, showDropdownMenuOnOrderId, updateOrder, updateRecentOrders])

   async function onConfirmOK() {
      setIsConfirmVisible(false)
      deleteOrder(deleteOrderId)
      setDeleteOrderId(null)
   }

   function onConfirmCancel() {
      setIsConfirmVisible(false)
      setDeleteOrderId(null)
   }

   useEffect(() => {
      if (customer && (showDropdownMenuOnContactNum !== null)) {
         const contact = getContactsFromCustomerAsSortedArray(customer)[showDropdownMenuOnContactNum]
         setSelectedContact(contact)
      } else {
         setSelectedContact(null)
      }
   }, [customer, getContactsFromCustomerAsSortedArray, showDropdownMenuOnContactNum])

   useEffect(() => {
      if ((curTab === tab_names.sync) || (curTab === tab_names.unsync)) {
         setSearchBar(
            <Searchbar text={searchText} onSearch={onSearch} placeholder={trans((searchModeInCustomer === search_type.other_client) ? '?Chercher un client' : '?Chercher une commande')} onChange={(text) => setSearchText(text)} >
               <div className={styles_Searchbar.search_tools}>
                  <button className={(searchModeInCustomer !== search_type.order) ? styles_Searchbar.red : ''} onClick={() => { setSearchModeInCustomer(search_type.other_client) }}>
                     <FontAwesomeIcon icon={faUserTieHairLong} />
                  </button>
                  <button className={(searchModeInCustomer === search_type.order) ? styles_Searchbar.red : ''} onClick={() => { setSearchModeInCustomer(search_type.order) }}>
                     <FontAwesomeIcon icon={faFileLines} />
                  </button>
               </div>
            </Searchbar>)
      } else {
         setSearchBar(<Searchbar onSearch={onSearch} />)
      }
   }, [curTab, onSearch, searchModeInCustomer, searchText, setSearchModeInCustomer, trans])

   useEffect(() => {
      setDropdownMenuContact(
         <DropdownMenu noscrollbars right={-20} top={20} onSelect={onSelectContact}>
            <li disabled={(selectedContact?.email === null) || (selectedContact?.email.indexOf('@') === -1)} id={menu_items.writeMail}>
               <FontAwesomeIcon icon={faEnvelope} />
               {trans('?Ecrire un mail')}
            </li>
            <li disabled={/*(selectedContact?.phone1 === null) && (selectedContact?.phone2 === null)*/true} id={menu_items.writeMail}>
               <FontAwesomeIcon icon={faPhone} />
               {trans('?Appeler')}
            </li>
         </DropdownMenu>
      )
   }, [curCustomer, customer, onSelectContact, selectedContact, trans])

   useEffect(() => {
      setDropdownMenuAddress(
         <DropdownMenu noscrollbars right={-20} top={20} onSelect={onSelectAddress}>
            <li disabled={curCustomer === customer?.id} id={menu_items.setActive}>
               <FontAwesomeIcon icon={faBadgeCheck} />
               {trans('?Sélectionner')}
            </li>
            <li disabled id={menu_items.createOrder}>
               <FontAwesomeIcon icon={faBagShopping} />
               {trans('?Créer une commande')}
            </li>
         </DropdownMenu>
      )
   }, [curCustomer, customer?.id, onSelectAddress, trans])

   useEffect(() => {
      if (selectedOrder) {
         const val = selectedOrder.readonly
         console.log('SEL ORDER', { ...selectedOrder }, val);
         setDropdownMenuOrders(
            <DropdownMenu noscrollbars right={-20} top={20} onSelect={onSelectMenuOrder}>
               {!selectedOrder.readonly &&
                  <>
                     <li id={menu_items.doEdit}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                        {trans('?Modifier')}
                     </li>
                     <li disabled={curOrder === showDropdownMenuOnOrderId} id={menu_items.setActive}>
                        <FontAwesomeIcon icon={faBadgeCheck} />
                        {trans('?Sélectionner')}
                     </li>
                  </>
               }
               {selectedOrder.readonly &&
                  <li id={menu_items.doSee}>
                     <FontAwesomeIcon icon={faEye} />
                     {trans('?Voir')}
                  </li>
               }
               <li id={menu_items.sendPdf}>
                  <FontAwesomeIcon icon={faFilePdf} />
                  {trans('?Envoyer en PDF à...')}
               </li>
               <li id={menu_items.doClone}>
                  <FontAwesomeIcon icon={faClone} />
                  {trans('?Dupliquer')}
               </li>
               {!selectedOrder?.readonly && <li id={menu_items.doDelete}>
                  <FontAwesomeIcon icon={faTrashCan} />
                  {trans('?Supprimer')}
               </li>}
               {!selectedOrder.readonly && <li id={menu_items.doSync} disabled={(selectedOrder?.status !== order_status.new) || !canSyncOrder(selectedOrder)}>
                  <FontAwesomeIcon icon={faCloudArrowUp} />
                  {trans('?Synchroniser')}
               </li>}
            </DropdownMenu>
         )
      }
   }, [canSyncOrder, curOrder, onSelectMenuOrder, order_status.new, selectedOrder, showDropdownMenuOnOrderId, trans])

   const onShowMenuContact = useCallback(
      (evt, num) => {
         evt.stopPropagation()
         if (showDropdownMenuOnContactNum === num) {
            setShowDropdownMenuOnContactNum(null)
         } else {
            setShowDropdownMenuOnContactNum(num)
         }
      },
      [showDropdownMenuOnContactNum]
   )

   const onShowMenuAddress = useCallback(
      (evt, num) => {
         evt.stopPropagation()
         if (showDropdownMenuOnAddressNum === num) {
            setShowDropdownMenuOnAddressNum(null)
         } else {
            setShowDropdownMenuOnAddressNum(num)
         }
      },
      [showDropdownMenuOnAddressNum]
   )

   function onCancelPickMailAddress() {
      setIsMailAddressPickerVisible(false)
   }

   const onValidatePickMailAddress = useCallback(async (arr_emails, message) => {
      console.log(arr_emails)
      setIsMailAddressPickerVisible(false)

      const order = await getOrderById(lastPickedOrderId)
      awPushPDF('CDE', order, arr_emails, message)
   }, [awPushPDF, getOrderById, lastPickedOrderId])

   const buildPhones = useCallback((prefix, phone1, phone2, country) => {
      const arr = [phone1, phone2].reduce((prev, phone, idx) => {
         if (!phone) {
            return [...prev]
         } else {
            let num
            if (formatPhone) {
               // https://www.npmjs.com/package/libphonenumber-js
               num = parsePhoneNumber(phone?.replaceAll(' ', ''), country)?.formatInternational()
            } else {
               num = phone
            }
            if (num === undefined) {   // Si country inconnue par exemple, ou autre pb de formatage
               num = phone
            }
            if (num) {
               return [...prev, <div key={`${prefix}_phone_${idx}`}><FontAwesomeIcon icon={faPhone} />{num}</div>]
            } else {
               return [...prev]
            }
         }
      }, [])
      return arr
   }, [formatPhone])

   const buildContactsLines = useCallback((customer) => {
      const contacts = getContactsFromCustomerAsSortedArray(customer)

      if (contacts) {
         // console.log('+++ADDR', contacts);
         return contacts.map((a_contact, idx) => {
            return (
               <tr key={`contacts_line_${idx}`}>
                  <td className={styles.cont_type}>
                     {a_contact.type}
                  </td>
                  <td className={styles.cont_name}>
                     {a_contact.name}
                  </td>
                  <td className={styles.cont_phone_mail}>
                     {buildPhones('contacts', a_contact.phone1, a_contact.phone2, a_contact.country)}
                     {a_contact.email && <div><FontAwesomeIcon icon={faEnvelopeSolid} />{a_contact.email}</div>}
                  </td>
                  <td className={styles.cont_actions}>
                     <span onClick={(evt) => onShowMenuContact(evt, idx)}>
                        {/* <FontAwesomeIcon icon={faGear} /> */}
                        {trans('?Détail', true)}
                        <FontAwesomeIcon icon={faAngleRight} />
                        {showDropdownMenuOnContactNum === idx ? dropdownMenuContact : ''}
                     </span>
                  </td>
               </tr>
            )
         })
      } else {
         return null
      }
   }, [buildPhones, dropdownMenuContact, getContactsFromCustomerAsSortedArray, onShowMenuContact, showDropdownMenuOnContactNum, trans])

   const onOrderLineClick = useCallback(
      (evt, num) => {
         // console.log('doNothing')
         evt.stopPropagation()
         if (showDropdownMenuOnOrderId !== null) {
            prepShowDropDown(null)
         } else {
            updateRecentOrders(num)
            navigate(`/order-detail?id=${num}`, { replace: false })
         }
      },
      [navigate, prepShowDropDown, showDropdownMenuOnOrderId, updateRecentOrders]
   )

   const onChangeChoice = useCallback((id, state) => {
      console.log(
         `Item ${id}${state === CheckboxStates.Checked ? 'Checked' : 'Unchecked'
         }`
      )
   }, [])

   const onOrderShowMenu = useCallback(
      (evt, id) => {
         evt.stopPropagation()
         if (showDropdownMenuOnOrderId === id) {
            prepShowDropDown(null)
         } else {
            prepShowDropDown(id)
         }
      },
      [prepShowDropDown, showDropdownMenuOnOrderId]
   )

   const buildOrderLines = useCallback((orders, with_sync) => {
      // console.log('+++ORDERS', orders);
      if (orders) {
         return orders?.map((an_order) => {
            // const a_customer = getCustomerById(an_order.customer_id)
            // console.log(a_customer);
            // const devise = a_customer?.tarifs.ces.currency
            const devise = customer?.tarifs.ces.currency
            return (
               <tr key={`line_${an_order.id}`} className={(an_order.id === curOrder) ? styles.selected : ''} onClick={(evt) => { onOrderLineClick(evt, an_order.id) }}>
                  <td className={styles.checkbox}>
                     <div>
                        <Checkbox small light checked={(an_order.id === curOrder) ? CheckboxStates.Checked : CheckboxStates.Unchecked} onChange={(state) => onChangeChoice(an_order.id, state)} />
                     </div>
                  </td>
                  <td className={styles.order_type}>
                     <div>
                        {(an_order.type === type_order.commande) && <FontAwesomeIcon icon={faGem} />}
                        {(an_order.type === type_order.implant) && <FontAwesomeIcon icon={faShelves} />}
                        {(an_order.type === type_order.confie) && <FontAwesomeIcon icon={faHandHoldingBox} />}
                        {(an_order.type === type_order.devis) && <FontAwesomeIcon icon={faFilePen} />}
                        <span>{an_order.type}</span>
                     </div>
                  </td>
                  <td className={styles.num_order}>
                     <div>
                        <span>{an_order.id}</span>
                        {an_order.mark && <span><FontAwesomeIcon icon={faBookmark} />{an_order.mark}</span>}
                     </div>
                  </td>
                  <td className={styles.order_date}>
                     <div>
                        <span>{an_order.date}</span>
                        {!an_order.readonly && <span>{an_order.time}</span>}
                     </div>
                  </td>
                  <td className={styles.order_total_price}>{formatCESPrice(calcTotalForOrder(an_order), devise)}</td>
                  {with_sync && <td className={styles.signature}>
                     <div>
                        {an_order.signature &&
                           <FontAwesomeIcon icon={faSignature} />
                        }
                     </div>
                  </td>}
                  {with_sync &&
                     <td className={styles.order_sync}>
                        {(an_order.status !== order_status.new) && (an_order.status !== order_status.deleted) && (an_order.status !== order_status.locked4sync) &&
                           <div className={styles.green}>
                              <FontAwesomeIcon icon={faCloud} />
                           </div>
                        }
                        {an_order.status === order_status.locked4sync &&
                           <div className={styles.orange}>
                              <FontAwesomeIcon icon={faCloudXmark} />
                           </div>
                        }
                        {an_order.status === order_status.deleted &&
                           <div className={styles.red}>
                              <FontAwesomeIcon icon={faTrashXmark} />
                           </div>
                        }
                     </td>
                  }
                  <td className={styles.order_actions}>
                     <span onClick={(evt) => onOrderShowMenu(evt, an_order.id)}>
                        {/* <FontAwesomeIcon icon={faGear} /> */}
                        {trans('?Détail', true)}
                        <FontAwesomeIcon icon={faAngleRight} />
                        {showDropdownMenuOnOrderId === an_order.id ? dropdownMenuOrders : ''}
                     </span>
                  </td>
               </tr>
            )
         })
      } else {
         return null
      }
   }, [calcTotalForOrder, curOrder, customer?.tarifs.ces.currency, dropdownMenuOrders, formatCESPrice, onChangeChoice, onOrderLineClick, onOrderShowMenu, order_status.deleted, order_status.locked4sync, order_status.new, showDropdownMenuOnOrderId, trans, type_order.commande, type_order.confie, type_order.devis, type_order.implant])

   const buildAddressesLines = useCallback((customer) => {
      const addresses = getAddressesFromCustomerAsSortedArray(customer)

      if (addresses) {
         return addresses.map((an_address, idx) => {
            let icon, text
            if (an_address.id === type_address.siege) {
               icon = faBuilding
               text = tag_address.siege[currentLang ?? 'fr']
            } else if (an_address.id.startsWith(type_address.ecrins)) {
               icon = faBox
               text = tag_address.ecrins[currentLang ?? 'fr']
            } else if (an_address.id.startsWith(type_address.pos)) {
               icon = faBagsShopping
               text = tag_address.pos[currentLang ?? 'fr']
            } else {
               icon = faStore
               text = tag_address.other[currentLang ?? 'fr']
            }
            return (
               <tr key={`addresses_line_${idx}`}>
                  <td className={styles.addr_type}>
                     <div>
                        <FontAwesomeIcon icon={icon} />
                        <span>{text}</span>
                     </div>
                  </td>
                  <td className={styles.addr_name}>
                     {an_address.name}
                  </td>
                  <td className={styles.addr_address}>
                     <div>
                        {buildAddress(an_address).map((line, idx) => <span key={idx}>{line}</span>)}
                     </div>
                  </td>
                  <td className={styles.addr_phone_mail}>
                     {buildPhones('addresses', an_address.phone1, an_address.phone2, an_address.country)}
                  </td>
                  <td className={styles.addr_actions}>
                     <span onClick={(evt) => onShowMenuAddress(evt, idx)}>
                        {/* <FontAwesomeIcon icon={faGear} /> */}
                        {trans('?Détail', true)}
                        <FontAwesomeIcon icon={faAngleRight} />
                        {showDropdownMenuOnAddressNum === idx ? dropdownMenuAddress : ''}
                     </span>
                  </td>
               </tr>
            )
         })
      } else {
         return null
      }
   }, [getAddressesFromCustomerAsSortedArray, type_address.siege, type_address.ecrins, type_address.pos, buildAddress, buildPhones, trans, showDropdownMenuOnAddressNum, dropdownMenuAddress, tag_address.siege, tag_address.ecrins, tag_address.pos, tag_address.other, currentLang, onShowMenuAddress])

   useEffect(() => {
      setContactsLines(buildContactsLines(customer))
   }, [buildContactsLines, customer])

   // console.log('+++Contacts', contactsLines)

   useEffect(() => {
      setAddressesLines(buildAddressesLines(customer))
   }, [buildAddressesLines, customer])

   const sortOrders = (a, b) => {
      let comp = 0
      if ((typeof a.date === 'string') && (typeof b.date === 'string')) {
         comp = b.date.localeCompare(a.date)
      }
      if (comp === 0) {
         comp = b.id.localeCompare(a.id)
      }
      return comp
   }

   /*******************
    * SYNC
    *******************/

   useEffect(() => {
      setLinesSyncOrders(buildOrderLines(filteredSyncOrders, false))
   }, [buildOrderLines, filteredSyncOrders])

   useEffect(() => {
      if (live_sync_orders && (curTab === tab_names.sync)) {
         console.log('LIVE SYNC', live_sync_orders);
         const str = searchText?.trim().toLowerCase()
         let arr = live_sync_orders
         if (str && str.length && (searchModeInCustomer === search_type.order)) {
            arr = arr?.filter((an_order) => {
               return String(an_order.id).toLowerCase().includes(str) || an_order.mark?.toLowerCase().includes(str)
            })
         }

         const nbPagesMax = Math.ceil(arr.length / listPageLength)
         const curPage = Math.max(1, Math.min(nbPagesMax, curPageSyncOrders))
         const start = (curPage - 1) * listPageLength
         setFilteredSyncOrders(arr.sort(sortOrders).slice(start, start + listPageLength))
         setNbPagesSyncOrders(nbPagesMax)
         setCurPageSyncOrders(curPage)
      }
   }, [curPageSyncOrders, curTab, getCustomerById, listPageLength, live_sync_orders, searchModeInCustomer, searchText])

   const onFirstPageChangeSyncOrders = useCallback((val) => {
      console.log('onFirstPageChangeSyncOrders', val)
      setFirstPageSyncOrders(val)
   }, [])

   const onPageChangeSyncOrders = useCallback((val) => {
      console.log('onPageChangeSyncOrders', val)
      setCurPageSyncOrders(val)
   }, [])

   useEffect(() => {
      setPagesButtonsSyncOrders(
         <PagesButtons from={firstPageSyncOrders} max={nbPagesSyncOrders} selected={curPageSyncOrders} onFirstPageChange={onFirstPageChangeSyncOrders} onPageChange={onPageChangeSyncOrders}></PagesButtons>
      )
   }, [curPageSyncOrders, firstPageSyncOrders, nbPagesSyncOrders, onFirstPageChangeSyncOrders, onPageChangeSyncOrders])

   /*******************
    * UNSYNC
    *******************/

   useEffect(() => {
      setLinesUnsyncOrders(buildOrderLines(filteredUnsyncOrders, true))
   }, [buildOrderLines, filteredUnsyncOrders])

   useEffect(() => {
      if (live_unsync_orders && (curTab === tab_names.unsync)) {
         console.log('LIVE UNSYNC', live_unsync_orders);
         const str = searchText?.trim().toLowerCase()
         let arr = live_unsync_orders
         if (str && str.length && (searchModeInCustomer === search_type.order)) {
            arr = arr?.filter((an_order) => {
               return String(an_order.id).toLowerCase().includes(str) || an_order.mark?.toLowerCase().includes(str)
            })
         }

         const nbPagesMax = Math.ceil(arr.length / listPageLength)
         const curPage = Math.max(1, Math.min(nbPagesMax, curPageUnsyncOrders))
         const start = (curPage - 1) * listPageLength
         setFilteredUnsyncOrders(arr.sort(sortOrders).slice(start, start + listPageLength))
         setNbPagesUnsyncOrders(nbPagesMax)
         setCurPageUnsyncOrders(curPage)
      }


   }, [curPageUnsyncOrders, curTab, listPageLength, live_unsync_orders, searchModeInCustomer, searchText])

   const onFirstPageChangeUnsyncOrders = useCallback((val) => {
      console.log('onFirstPageChangeUnsyncOrders', val)
      setFirstPageUnsyncOrders(val)
   }, [])

   const onPageChangeUnsyncOrders = useCallback((val) => {
      console.log('onPageChangeUnsyncOrders', val)
      setCurPageUnsyncOrders(val)
   }, [])

   useEffect(() => {
      setPagesButtonsUnsyncOrders(
         <PagesButtons from={firstPageUnsyncOrders} max={nbPagesUnsyncOrders} selected={curPageUnsyncOrders} onFirstPageChange={onFirstPageChangeUnsyncOrders} onPageChange={onPageChangeUnsyncOrders}></PagesButtons>
      )
   }, [curPageUnsyncOrders, firstPageUnsyncOrders, nbPagesUnsyncOrders, onFirstPageChangeUnsyncOrders, onPageChangeUnsyncOrders])

   /*******************
    * FIN UNSYNC & SYNC
    *******************/

   function switchTab(tab) {
      setCurTab(tab)
   }

   function onGlobalClick(evt) {
      evt.stopPropagation()
      setShowDropdownMenuOnAddressNum(null)
      setShowDropdownMenuOnContactNum(null)
      setShowDropdownMenuOnOrderId(null)
   }

   function toggleItemsPerPageShowList() {
      setIsItemsPerPageOpen(!isItemsPerPageOpen)
   }

   function onItemsPerPageSelect(id) {
      // console.log('>>>>>>', id)
      setListPageLength(id)
      setIsItemsPerPageOpen(false)
   }

   function makeActive() {
      setCustomerIdAsCurrent(customer.id)
   }

   function leaveCustomer() {
      setCurCustomer(null)
      setCurOrder(null)
   }

   const doCreateOrder = useCallback(async () => {
      const new_order = makeTemplateOrder(customer.id)
      const curid = await createOrder(new_order)
      navigate(`/order-detail?id=${curid}`, { replace: false })
   }, [createOrder, customer, makeTemplateOrder, navigate])

   return (
      <Fragment>
         <Menu page="customers" />
         <div className={styles.customer_detail} onClick={onGlobalClick}>
            {searchBar}
            <div className={styles.content}>
               <div className={styles.header_bg}>
                  <div className={styles.header}>
                     <h1>
                        <div className={styles.cust_name}>{customer?.name}<span>{customer?.id}</span></div>
                        {(curCustomer === customer?.id) &&
                           <Button small secondary red onClick={leaveCustomer}>{trans("?Quitter client")}</Button>
                        }
                        {(curCustomer !== customer?.id) &&
                           <Button small onClick={makeActive}>{trans("?Sélectionner")}</Button>
                        }
                        <Button small onClick={doCreateOrder}>{trans("?Créer une commande")}</Button>
                     </h1>
                     <div className={styles.filters}>
                        {/* <span className={`${(curTab === tab_names.info) ? styles.selected : ''}`} onClick={() => switchTab(tab_names.info)}> URGENCE TARIFS */}
                        <span className={`${(curTab === tab_names.info) ? styles.selected : ''} ${styles.disabled}`} /*onClick={() => switchTab(tab_names.info)} TODO Remettre quand on aura les infos des 2 prems tabs*/>
                           <FontAwesomeIcon icon={faCircleInfo} />
                           {trans('?Informations')}
                           <div className={styles.bot_border} />
                        </span>
                        {/* <span className={`${(curTab === tab_names.stats) ? styles.selected : ''} ${styles.disabled}`} onClick={() => switchTab(tab_names.stats)}>
                           <FontAwesomeIcon icon={faChartPie} />
                           {trans('?Stats')}
                           <div className={styles.bot_border} />
                        </span> */}
                        <span className={(curTab === tab_names.contacts) ? styles.selected : ''} onClick={() => switchTab(tab_names.contacts)}>
                           <FontAwesomeIcon icon={faAddressBook} />
                           {trans('?Contact', true)}
                           <div className={styles.bot_border} />
                        </span>
                        <span className={(curTab === tab_names.addresses) ? styles.selected : ''} onClick={() => switchTab(tab_names.addresses)}>
                           <FontAwesomeIcon icon={faLocationDot} />
                           {trans('?Adresse', true)}
                           <div className={styles.bot_border} />
                        </span>
                        <span className={(curTab === tab_names.orders) ? styles.selected : ''} onClick={() => switchTab(tab_names.orders)}>
                           <FontAwesomeIcon icon={faBagShoppingSolid} />
                           {trans('?Traitée', true)}
                           <div className={styles.bot_border} />
                        </span>
                        <span className={(curTab === tab_names.unsync) ? styles.selected : ''} onClick={() => switchTab(tab_names.unsync)}>
                           <FontAwesomeIcon icon={faCloudSlash} />
                           {trans('?En cours oMe')}
                           <div className={styles.bot_border} />
                        </span>
                     </div>
                  </div>
               </div>
               {/* {(curTab === tab_names.stats) &&
                  <div className={styles.detail_stats}>
                     <div className={styles.stat_buttons}>
                        <Button>Stat 1</Button>
                        <Button>Stat 2</Button>
                        <Button>Stat 3</Button>
                        <Button>Stat 4</Button>
                     </div>
                     <div>
                        <GraphPie num={1} year={2021} />
                        <GraphPie num={2} year={2022} />
                     </div>
                     <div>
                        <GraphLine />
                     </div>
                     <div>
                        <GraphPie num={3} year={2020} />
                        <GraphPie num={4} year={2019} />
                     </div>
                     <div>
                        <GraphLine />
                     </div>
                  </div>
               } */}
               {(curTab === tab_names.info) &&
                  <div className={styles.detail_info}>
                     <div className={styles.close_header}></div>{/* Pour fermer l'entête proprement */}
                     {stats}
                  </div>
               }
               {(curTab === tab_names.contacts) &&
                  <div className={styles.detail_contacts}>
                     <table>
                        <thead>
                           {/* "0504771-1": { "name": "WONG", "addr1": null, "addr2": null, "zip": null, "city": null, "country": null, "email": "Maggie.Wong@dfs.com", "phone1": null, "phone2": null }, */}
                           <tr>
                              <th>{trans('?Type')}</th>
                              <th>{trans('?Nom')}</th>
                              <th>{trans('?Téléphone')} / {trans('?Email')}</th>
                              <th></th>
                           </tr>
                        </thead>
                        <tbody>{contactsLines}</tbody>
                     </table>
                  </div>
               }
               {(curTab === tab_names.addresses) &&
                  <div className={styles.detail_addresses}>
                     <table>
                        <thead>
                           {/* "AT": { "name": "SAINT HONORE", "addr1": "?", "addr2": null, "zip": null, "city": null, "country": "France" }, */}
                           <tr>
                              <th>{trans('?Type')}</th>
                              <th>{trans('?Nom')}</th>
                              <th>{trans('?Adresse')}</th>
                              <th>{trans('?Téléphone')}</th>
                              <th></th>
                           </tr>
                        </thead>
                        <tbody>{addressesLines}</tbody>
                     </table>
                  </div>
               }
               {(curTab === tab_names.orders) &&
                  <div className={styles.detail_orders}>
                     <table>
                        <thead>
                           <tr>
                              <th></th>
                              <th>{trans('?Type')}</th>
                              <th>{trans('?N° Cde')}</th>
                              <th>{trans('?Date')}</th>
                              <th className={styles.order_total_price}>{trans('?Prix total')}</th>
                              <th></th>
                           </tr>
                        </thead>
                        <tbody>{linesSyncOrders}</tbody>
                     </table>
                  </div>
               }
               {(curTab === tab_names.unsync) &&
                  <div className={styles.detail_unsent_orders}>
                     <table>
                        <thead>
                           <tr>
                              <th></th>
                              <th>{trans('?Type')}</th>
                              <th>{trans('?N° Cde')}</th>
                              <th>{trans('?Date')}</th>
                              <th className={styles.order_total_price}>{trans('?Prix total')}</th>
                              <th className={styles.signature}><FontAwesomeIcon icon={faSignature} /></th>
                              <th className={styles.order_sync}>{trans('?Sync')}</th>
                              <th></th>
                           </tr>
                        </thead>
                        <tbody>{linesUnsyncOrders}</tbody>
                     </table>
                  </div>
               }
               {((curTab === tab_names.orders) || (curTab === tab_names.unsync)) &&
                  <div className={styles.footer_bg}>
                     <div className={styles.footer}>
                        <span>{trans('?Affichage de')}</span>
                        <DropdownField selectedValue={String(listPageLength)} showAbove height={36} onSelect={onItemsPerPageSelect} isOpen={isItemsPerPageOpen} onToggle={toggleItemsPerPageShowList} >
                           <Fragment key="10">10</Fragment>
                           <Fragment key="20">20</Fragment>
                           <Fragment key="50">50</Fragment>
                           <Fragment key="100">100</Fragment>
                           {/* <Fragment key="999999">{lowtrans("?Tous")}</Fragment> */}
                        </DropdownField>
                        <span>
                           {(listPageLength < 99999) ? trans('?par page') : ''}
                        </span>
                        <div>
                           {(listPageLength < 99999) && (curTab === tab_names.orders) && pagesButtonsSyncOrders}
                           {(listPageLength < 99999) && (curTab === tab_names.unsync) && pagesButtonsUnsyncOrders}
                           <span></span>
                           <Button>
                              <FontAwesomeIcon icon={faUpToLine} />
                           </Button>
                        </div>
                     </div>
                  </div>
               }
            </div>
         </div >
         {
            isConfirmVisible && (
               <Confirm yesno onOK={onConfirmOK} onCancel={onConfirmCancel} title={trans("?Suppression")} text={trans("?confirmer_suppression_bdc")} />
            )
         }
         {isMailAddressPickerVisible && (
            <MailAddressPicker customer={customer?.id} onCancel={onCancelPickMailAddress} onValidate={onValidatePickMailAddress} top={'20%'} minWidth={'30%'} maxWidth={'50%'} />
         )}
         <ToastContainer />
      </Fragment >
   )
}

export default CustomerDetail
